import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { Card, InputGroup, OverlayTrigger, Popover, Tab, Tabs } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import usePermissions from '@app/hooks/usePermissions';
import useQueryParams from '@app/hooks/useQueryParams';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getSort } from '@app/helpers/DataTableHelper';

import { getLeadByStatus, getLeadReason } from '@app/crud/customers/customer.crud';

import { useAppSelector, useFetch } from '@app/hooks';
import { getCurrentMonthPeriod, getPrevMonthPeriod } from '@app/pages/dashboard/helpers/DashboardHelper';
import ActivityLeadOnline from '@app/pages/leadsOnline/Partials/ActivityLeadOnline';
import LegendButton from '@app/pages/leadsOnline/Partials/LegendButton';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import InputFilter from '@app/partials/content/InputFilter';
import { TreeSelect } from '@app/partials/content/Tree';

import AchatCashDataTable from './AchatCashDataTable';

const counters = {
    new: 'achat_cash_new',
    'action-to-do': 'achat_cash_action_to_do',
    'action-to-come': 'achat_cash_action_to_come',
    all: 'achat_cash_all',
    close: 'achat_cash_close',
};

const AchatCash = () => {
    const [loadingActivity, setLoadingActivity] = useState(false);
    const [activeTab, setActiveTab] = useState('all');
    const { statusType } = useParams();
    const { hasPermission } = usePermissions();
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();
    const Intl = useIntl();
    const isAbandon = location?.pathname?.includes('leads-abandon');
    const storeName = `leads_achat_cash_${isAbandon ? 'abandon_' : ''}${statusType}`;
    const isClosedLostReason = useAppSelector(
        (state) =>
            state.filters?.leads_achat_cash_close?.filters?.leadStatus?.value === 'closed_lost' &&
            statusType === 'close',
    );
    const [slugMapped, setSlugMapped] = useState([]);

    const {
        items,
        start,
        filters,
        sortField,
        sortOrder,
        updatePendingFilters,
        releasePendingFilters,
        resetFilter,
        resetFilters,
    } = useFilters({
        name: storeName,
        persisted: false,
    });

    const { data, loading, handleFilter, handlePagination, handleSort } = useTableHandlers({
        filterStore: storeName,
        fetchAction: getLeadByStatus,
        counterName: counters[statusType],
        withStore: false,
        needUrlParams: true,
        paramsInterceptor: (params, interceptorFilters) => {
            const { leadType, leadTypeGroup, leadStatus } = interceptorFilters?.filters || null;
            const sort = getSort(interceptorFilters?.sortField, interceptorFilters?.sortOrder, 'created', 'desc');
            let leadTypes = [];
            if (leadType && leadType?.value) {
                leadTypes = [leadType?.value];
            } else if (isAbandon) {
                leadTypes = ['achat_cash_abandon'];
            } else if (!isAbandon && statusType === 'new') {
                leadTypes = ['achat_cash'];
            } else {
                leadTypes = ['achat_cash', 'achat_cash_abandon', 'local_achat_cash'];
            }

            return {
                ...params,
                page: interceptorFilters.page,
                limit: interceptorFilters.items,
                leadType: leadTypes,
                leadTypeGroup: leadTypeGroup && leadTypeGroup?.value ? [leadTypeGroup?.value] : [],
                leadStatus: leadStatus && leadStatus?.value ? [leadStatus?.value] : [],
                sort: `${sort?.field}_${sort?.order}`,
                isAchatCash: true,
            };
        },
    });

    const { data: reasonList } = useFetch({
        fetchAction: getLeadReason,
        autoFetch: true,
        resultInterceptor: (response) => {
            return formatResponse(response);
        },
    });
    const treeRef = useRef();

    useEffect(() => {
        if (queryParams?.leadTypeGroup) {
            setActiveTab(queryParams?.leadTypeGroup);
            history.replace(location.pathname);
        }
    }, [queryParams]);

    useEffect(() => {
        setActiveTab('all');
    }, [statusType]);

    useEffect(() => {
        handleFilter({
            leadTypeGroup: {
                value: activeTab !== 'all' ? activeTab : null,
            },
            status: {
                value: statusType.replaceAll('-', '_'),
            },
        });
    }, [handleFilter, activeTab, statusType]);

    const getOriginOptions = () => {
        const options = [
            {
                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' }),
                value: null,
            },
        ];

        if (data?.facets?.leadOrigins) {
            data.facets.leadOrigins.forEach((facet) => {
                options.push({
                    title: facet?.label,
                    value: facet?.key,
                });
            });
        }

        return options;
    };

    const formatResponse = (response) => {
        const nodes = Object.values(response?.result['close_lead_achat_cash_loose_reason']?.leadReasons)
            .map((reason) => {
                let slugList = [];
                const children = reason?.leadReasons.map((child) => {
                    slugList = [...slugList, child.slug];
                    return {
                        id: child.id,
                        label: child.name,
                        value: child.slug,
                        checked: true,
                    };
                });

                if (slugList.length === 0) {
                    slugList = [reason.slug];
                }

                setSlugMapped((prevSlugMapped) => ({
                    ...prevSlugMapped,
                    [reason.id]: slugList,
                }));

                return {
                    id: reason.id,
                    label: reason.name,
                    value: reason.slug,
                    checked: true,
                    children,
                };
            })
            .filter((node) => node !== null);

        return {
            id: 0,
            label: 'Tous',
            value: 'all',
            checked: true,
            expanded: true,
            children: nodes,
        };
    };

    const handleNodes = (nodes) => {
        const allSelected = nodes.some((node) => node.id === 0);

        if (allSelected) {
            return null;
        }

        if (nodes?.length > 0) {
            const slugs = nodes.map((node) => {
                if (node.hasOwnProperty('_children')) {
                    return slugMapped[node.id];
                }

                return [node.value];
            });
            return slugs.flat();
        }

        return null;
    };

    return (
        <FiltersProvider value={filters}>
            <Card className="p-4 position-relative" key={statusType}>
                <div className="btn-legend d-flex align-items-center justify-content-end">
                    <div className="mr-4">
                        <InputFilter
                            name="global"
                            id="global"
                            onChange={handleFilter}
                            placeholder={Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.GLOBAL' })}
                            prepend={
                                <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom-start"
                                    overlay={
                                        <Popover>
                                            <Popover.Title as="h3">
                                                {Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.GLOBAL.HELP.TITLE' })}
                                            </Popover.Title>
                                            <Popover.Content
                                                dangerouslySetInnerHTML={{
                                                    __html: Intl.formatHTMLMessage({
                                                        id: 'LEADS_ONLINE.FILTER.GLOBAL.HELP.CONTENT',
                                                    }),
                                                }}
                                            />
                                        </Popover>
                                    }
                                >
                                    <InputGroup.Text>
                                        <i className="las la-question-circle" />
                                    </InputGroup.Text>
                                </OverlayTrigger>
                            }
                        />
                    </div>
                    {hasPermission('LEAD_SHOW_ORIGIN_FILTER') && (
                        <Dropdown
                            value={filters?.leadOrigin?.value}
                            options={getOriginOptions()}
                            onChange={(e) => {
                                handleFilter({
                                    leadOrigin: {
                                        value: e.value,
                                    },
                                });
                            }}
                            optionLabel="title"
                            optionValue="value"
                            className="mr-4"
                            placeholder={Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.ORIGIN.ALL' })}
                        />
                    )}
                    <Dropdown
                        value={filters?.receiptedPeriod?.value}
                        options={[
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' }),
                                value: null,
                            },
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.CURRENT' }),
                                value: getCurrentMonthPeriod(),
                            },
                            {
                                title: Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.PREV' }),
                                value: getPrevMonthPeriod(),
                            },
                        ]}
                        onChange={(e) => {
                            handleFilter({
                                receiptedPeriod: {
                                    value: e.value,
                                },
                            });
                        }}
                        optionLabel="title"
                        optionValue="value"
                        placeholder={Intl.formatMessage({ id: 'LEADS_ONLINE.FILTER.RECEIPTED.ALL' })}
                    />
                    {isClosedLostReason && (
                        <div className="col-lg-3">
                            <TreeSelect
                                data={reasonList ?? []}
                                texts={{
                                    placeholder: Intl.formatMessage({
                                        id: 'LEADS_ONLINE.FILTER.REASON.ALL',
                                    }),
                                }}
                                showPartiallySelected
                                mode="multiselect"
                                ref={treeRef}
                                keepOpenOnSelect
                                onChange={(currentNode, selectedNodes) => {
                                    const nodeSlugs = handleNodes(selectedNodes);
                                    handleFilter({
                                        leadReason: {
                                            value: nodeSlugs,
                                        },
                                    });
                                }}
                            />
                        </div>
                    )}
                    <LegendButton
                        updatePendingFilters={updatePendingFilters}
                        releasePendingFilters={releasePendingFilters}
                        resetFilter={resetFilter}
                        activeTab={activeTab}
                    />
                </div>
                <Tabs
                    className="nav-tabs-line nav-tabs-line-primary nav-tabs-line-2x"
                    defaultActiveKey="all"
                    activeKey={activeTab}
                    id={activeTab}
                    onSelect={(e) => setActiveTab(e)}
                >
                    {data?.counter?.length &&
                        data.counter.map((tab) => (
                            <Tab
                                tabClassName="px-4 font-weight-bold"
                                eventKey={tab.key}
                                title={`${tab.label} (${tab.doc_count})`}
                                key={tab.key}
                            >
                                {activeTab === tab.key && (
                                    <AchatCashDataTable
                                        statusType={statusType}
                                        items={items}
                                        start={start}
                                        filters={filters}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        data={data}
                                        loading={loading || loadingActivity}
                                        handleFilter={handleFilter}
                                        handlePagination={handlePagination}
                                        handleSort={handleSort}
                                        resetFilters={resetFilters}
                                    />
                                )}
                            </Tab>
                        ))}
                </Tabs>
            </Card>
            <ActivityLeadOnline loading={loadingActivity} setLoading={setLoadingActivity} />
        </FiltersProvider>
    );
};

export default AchatCash;
