import { DevTool } from '@hookform/devtools';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';

import { ApiResponse } from '@app/helpers/AxiosHelper';

import { mapFieldFormToCustomer, mapFieldFormToSecuryweb } from '@app/crud/mapping/securyweb.map';
import {
    createContract,
    createDraft,
    deleteDraft,
    getContractPrint,
    getDealershipLaw,
} from '@app/crud/securyweb/securyweb.crud';
import {
    ContractType,
    LawCode,
    LawCodeType,
    MappedCustomer,
    StatusContractSlug,
} from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useAppSelector, useFetch, useThunkDispatch } from '@app/hooks';
import {
    formatFieldBeforeSend,
    isBorneProduct,
} from '@app/pages/customers/Modals/SecuryCarContract/Helpers/SecurywebHelper';
import SecuryCarContractStepInfos from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContractStepInfos';
import SecuryCarContractStepSubscriber from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContractStepSubscriber';
import SecuryCarContractStepVehicle from '@app/pages/customers/Modals/SecuryCarContract/SecuryCarContractStepVehicle';
import { fetchContracts, updateCustomer } from '@app/store/customers/customers.thunk';
import { actions } from '@app/store/modal/modal.store';

import Loader from '@app/partials/content/Loader';
import ModalDefault from '@app/partials/content/modals/Modal.default';
import ModalStepper, { ModalStepperType } from '@app/partials/content/modals/ModalStepper';
import toast from '@app/partials/content/Toast';

type SecuryCarContractStepsProps = {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
};

const SecuryCarContractSteps = ({ showModal, setShowModal }: SecuryCarContractStepsProps) => {
    const Intl = useIntl();
    const methods = useForm();
    const { id: customerId } = useParams<{ id: string }>();
    const history = useHistory();
    const dispatch = useThunkDispatch();
    const { userData } = useAppSelector((state) => state.auth);

    const [serverError, setServerError] = useState<ApiResponse<{ message: string }>>();
    const { handleSubmit, watch, getValues, setValue, trigger, formState } = methods;
    const { errors, isValid, isSubmitted } = formState;
    const [isLoading, setIsLoading] = useState(false);
    const {
        fetch: fetchDealerLaw,
        data: dealerLaw,
        error: dealerLawError,
    } = useFetch<string, ApiResponse<LawCodeType>, LawCodeType>({
        fetchAction: getDealershipLaw,
    });
    const [isDraftToValidSM, setIsDraftToValidSM] = useState(false);

    const contractStatut = watch('statut');

    const isBorne = isBorneProduct(watch('product'));
    const contractType = isBorne ? ContractType.ELECTRIQUE : ContractType.CLASSIQUE;

    const getSteps = () => {
        const steps = [
            {
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.STEP.CONTRACT_INFO.TITLE' }),
                status: 'pending',
                content: <SecuryCarContractStepInfos />,
            },
            {
                name: isBorne
                    ? Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.CATEGORY.SUBSCRIBER.TITLE' })
                    : Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.STEP.SUBSCRIBER.TITLE' }),
                status: 'pending',
                content: <SecuryCarContractStepSubscriber />,
            },
        ];
        if (!isBorne) {
            steps.push({
                name: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.SECURYCAR_CONTRACT.STEP.VEHICLE.TITLE' }),
                status: 'pending',
                content: <SecuryCarContractStepVehicle />,
            });
        }
        return steps;
    };

    const [stepperState, setStepperState] = useState<ModalStepperType>({
        currentStep: 0,
        steps: getSteps(),
    });

    useEffect(() => {
        setStepperState((prevState) => ({
            ...prevState,
            steps: getSteps(),
        }));
    }, [isBorne]);

    const commonFields = [
        [
            'offer',
            'dealership',
            'dealer',
            'product',
            'initialDuration',
            'subscriptionDate',
            'priceWithTaxes',
            'priceWithoutTaxe',
            'iban',
            'bic',
        ],
        ['subscriber'],
    ];

    let extraFields: string[][];
    if (isBorne) {
        extraFields = [];
    } else {
        extraFields = [['vehicle']];
        commonFields[1].push('beneficiary');
    }

    const fieldsToCheck = [...commonFields, ...extraFields];

    const handleChangeStep = (step: number) => {
        setStepperState((prevState) => {
            let newStep = prevState.currentStep;

            newStep = step;

            return { ...prevState, currentStep: newStep };
        });
    };

    const handleServerError = (messageId: string) => {
        toast({
            variant: variantTypes.DANGER,
            message: Intl.formatMessage({ id: messageId }),
        });
    };

    const handleSuccess = (messageId: string) => {
        toast({
            variant: variantTypes.SUCCESS,
            message: Intl.formatMessage({ id: messageId }),
        });
    };

    const handleFetchContracts = () => {
        if (customerId) {
            dispatch(fetchContracts(customerId));
        }
    };

    const closeAndResetModal = () => {
        setStepperState((prevState) => ({
            ...prevState,
            steps: prevState.steps.map((step) => ({
                ...step,
                status: 'pending',
            })),
            currentStep: 0,
        }));
        setShowModal(false);
    };

    const onDeleteDraft = async () => {
        const data = getValues();
        const mappedData = mapFieldFormToSecuryweb(data);

        if (mappedData.id) {
            setIsLoading(true);
            try {
                await deleteDraft(mappedData.id, mappedData.typeContrat);
                setValue('id', null);
                handleSuccess('TRANSLATOR.SUCCESS');
                closeAndResetModal();
                handleFetchContracts();
            } catch {
                handleServerError('TRANSLATOR.ERROR');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const onDraftSubmit = async () => {
        const data = formatFieldBeforeSend(getValues());
        const mappedData = mapFieldFormToSecuryweb(data);

        setIsLoading(true);
        try {
            const response = await createDraft(mappedData, data.customerId);
            if (response?.result?.id) {
                setValue('id', response.result.id);
            }

            const updateCustomerData: MappedCustomer = mapFieldFormToCustomer(customerId, userData, data);
            dispatch(updateCustomer(Number(customerId), updateCustomerData, history, false, false));

            handleSuccess('TRANSLATOR.SUCCESS');
            setServerError(null);
            closeAndResetModal();
            handleFetchContracts();
        } catch (error: unknown) {
            setServerError(error as ApiResponse<{ message: string }>);
        } finally {
            setIsLoading(false);
        }
    };

    const handleContractPrint = (id: string) => {
        setIsLoading(true);
        getContractPrint(id, contractType)
            .then((response) => {
                const fileURL = URL.createObjectURL(response);
                const link = document.getElementById('viewPdf');
                link.setAttribute('href', fileURL);
                link.click();
                handleFetchContracts();
            })
            .catch(() => handleServerError('TRANSLATOR.ERROR'))
            .finally(() => {
                setIsLoading(false);
                closeAndResetModal();
            });
    };

    const manageContract = async () => {
        const data = formatFieldBeforeSend(getValues());
        const mappedData = mapFieldFormToSecuryweb(data);

        if (contractStatut === StatusContractSlug.VALID) {
            const optinGroup = watch('optin_group');
            const optinPartner = watch('optin_partner');

            if (optinGroup && optinPartner) {
                mappedData.optins = 'Groupe, Partenaire';
            } else if (optinGroup && !optinPartner) {
                mappedData.optins = 'Groupe';
            } else if (!optinGroup && optinPartner) {
                mappedData.optins = 'Partenaire';
            } else {
                mappedData.optins = 'Aucun';
            }
        }

        setIsLoading(true);
        try {
            const response = await createContract(mappedData, data.customerId);
            if (response?.result?.id) {
                if (isDraftToValidSM) {
                    handleContractPrint(response.result.id);
                }
                setValue('id', response.result.id);
            }

            const updateCustomerData: MappedCustomer = mapFieldFormToCustomer(customerId, userData, data);
            dispatch(updateCustomer(Number(customerId), updateCustomerData, history, false, false));

            handleSuccess('TRANSLATOR.SUCCESS');
            setServerError(null);
            if (!isDraftToValidSM) handleFetchContracts();
            return true;
        } catch (error: unknown) {
            setServerError(error as ApiResponse<{ message: string }>);
            return false;
        } finally {
            if (!isDraftToValidSM) {
                setIsLoading(false);
            }
        }
    };

    const onValidSubmit = async () => {
        setValue('statut', StatusContractSlug.TO_SIGN);

        setTimeout(async () => {
            const isTriggered = await trigger();
            if (isTriggered) {
                const result = await manageContract();
                if (result && !isDraftToValidSM) {
                    closeAndResetModal();
                } else {
                    setValue('statut', StatusContractSlug.DRAFT);
                }
            } else {
                setValue('statut', StatusContractSlug.DRAFT);
            }
        });
    };

    const onSMSubmit = () => {
        const data = formatFieldBeforeSend(getValues());

        if (data.id) {
            handleContractPrint(data.id);
        } else {
            handleServerError('TRANSLATOR.ERROR');
        }
    };

    const onSESubmit = async () => {
        const prevStatus = getValues('statut');
        setValue('statut', StatusContractSlug.VALID);

        setTimeout(async () => {
            const isTriggered = await trigger();
            if (isTriggered) {
                setValue('statut', StatusContractSlug.TO_SIGN);
                const result = await manageContract();

                if (result) {
                    dispatch(
                        actions.modalChange('contract_securycar_cgv', {
                            idContract: getValues('id'),
                            contractType,
                        }),
                    );
                } else {
                    setValue('statut', prevStatus);
                }
            } else {
                setValue('statut', prevStatus);
            }
        });
    };

    const onError = () => {
        const { steps } = stepperState;

        fieldsToCheck.forEach((fields, index) => {
            steps[index].status = fields.some((key) => Object.keys(errors).includes(key)) ? 'error' : 'valid';
        });
        setStepperState({ ...stepperState, steps });

        handleServerError('FORM.ERROR.VALIDATE');
    };

    const handleSubmitSM = () => {
        if (contractStatut === StatusContractSlug.DRAFT) {
            setIsDraftToValidSM(true);
            handleSubmit(onValidSubmit, onError)();
        } else {
            handleSubmit(onSMSubmit, onError)();
        }
    };

    useEffect(() => {
        if (getValues('dealership')) {
            fetchDealerLaw(getValues('dealership') as string);
        }
    }, [fetchDealerLaw, getValues('dealership')]);

    useEffect(() => {
        if (dealerLawError) {
            handleServerError('TRANSLATOR.ERROR');
        }
    }, [dealerLawError]);

    useEffect(() => {
        if (showModal) {
            setServerError(null);
        }
    }, [showModal]);

    useEffect(() => {
        if (isValid || !isSubmitted) return;
        onError();
    }, [errors]);

    return (
        <ModalDefault
            show={showModal}
            size="lg"
            bodyClassName="px-20"
            hideModal={closeAndResetModal}
            icon={<i className="la la-2x text-primary la-shield-alt" />}
            title={<FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.TITLE" />}
            body={
                <FormProvider {...methods}>
                    {process.env.NODE_ENV === 'development' && <DevTool control={methods.control} />}
                    {isLoading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
                    <ModalStepper state={stepperState} arrows setStep={handleChangeStep} navigate />
                    {serverError?.result && (
                        <Alert variant="danger">
                            <span>{serverError.result.message}</span>
                        </Alert>
                    )}
                    {stepperState.steps.map((step, index) => (
                        <div
                            key={`step-${step.name}`}
                            className={classNames({
                                'd-none': stepperState.currentStep !== index,
                                'securycar-handle-modal': stepperState.currentStep === index,
                            })}
                        >
                            {step.content}
                        </div>
                    ))}
                    <small>
                        <FormattedMessage id="TRANSLATOR.REQUIRED_FIELDS" />
                    </small>
                </FormProvider>
            }
            footer={
                <div className="d-flex justify-content-center">
                    {contractStatut === StatusContractSlug.VALID ? (
                        <Button variant="primary" className="mx-2" disabled={isLoading} onClick={closeAndResetModal}>
                            <FormattedMessage id="TRANSLATOR.CLOSE" />
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant="link"
                                className="text-gray-600"
                                disabled={contractStatut !== StatusContractSlug.DRAFT || !watch('id') || isLoading}
                                onClick={onDeleteDraft}
                            >
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.DELETE_DRAFT" />
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="mx-2"
                                disabled={contractStatut !== StatusContractSlug.DRAFT || isLoading}
                                onClick={handleSubmit(onDraftSubmit, onError)}
                            >
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.SAVE_DRAFT" />
                            </Button>
                            <Button
                                variant="outline-primary"
                                className="mx-2"
                                disabled={
                                    contractStatut === StatusContractSlug.VALID ||
                                    contractStatut === StatusContractSlug.TO_SIGN ||
                                    isLoading
                                }
                                onClick={handleSubmit(onValidSubmit, onError)}
                            >
                                <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.VALIDATE" />
                            </Button>
                            {dealerLaw.includes(LawCode.MANUAL) && (
                                <>
                                    <Button
                                        variant="primary"
                                        className="ml-2"
                                        onClick={handleSubmitSM}
                                        disabled={isLoading}
                                    >
                                        <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.MANUAL_SIGN" />
                                    </Button>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
                                    <a href="" target="_blank" id="viewPdf" aria-hidden />
                                </>
                            )}
                            {dealerLaw.includes(LawCode.ELEC) && (
                                <Button
                                    variant="primary"
                                    className="ml-2"
                                    onClick={handleSubmit(onSESubmit, onError)}
                                    disabled={isLoading}
                                >
                                    <FormattedMessage id="CUSTOMERS.MODAL.SECURYCAR_CONTRACT.ELECTRONIC_SIGN" />
                                </Button>
                            )}
                        </>
                    )}
                </div>
            }
        />
    );
};

export default SecuryCarContractSteps;
