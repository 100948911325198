import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import { routeTo } from '@app/helpers/RoutesHelper';

import ROUTES from '@app/router/Routes';

import Permission from '@app/partials/content/Permission';

import { checkIdenticalData } from '../Helpers/LeadOnlineHelper';

const LeadRowAction = ({ lead, children, setLoadingExistedCustomers }) => {
    const className = 'lead__row font-weight-semibold font-size-lg';

    if (!lead?.contact?.id) {
        return (
            <Permission
                permissions={['LEAD_TAKE_LEAD']}
                interceptor={() => !!lead?.owner?.id}
                fallback={() => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
                    <div
                        onClick={() => checkIdenticalData(lead, false, setLoadingExistedCustomers)}
                        role="button"
                        className={classNames(className, 'lead__row--show')}
                    >
                        {children}
                    </div>
                )}
            >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
                <div
                    onClick={() => checkIdenticalData(lead, true, setLoadingExistedCustomers)}
                    role="button"
                    className={classNames(className, 'lead__row--take')}
                >
                    {children}
                </div>
            </Permission>
        );
    }

    if (!lead?.owner?.id) {
        return (
            <Permission
                permissions={['LEAD_TAKE_LEAD']}
                fallback={() => (
                    <Link
                        to={routeTo(
                            ROUTES.CUSTOMER.PATH,
                            {
                                id: lead?.contact?.id,
                            },
                            {
                                action: 'viewLead',
                                leadId: lead?.id,
                            },
                        )}
                    >
                        <div className={classNames(className, 'lead__row--show')}>{children}</div>
                    </Link>
                )}
            >
                <Link
                    to={routeTo(
                        ROUTES.CUSTOMER.PATH,
                        {
                            id: lead?.contact?.id,
                        },
                        {
                            action: 'take',
                            leadId: lead.id,
                        },
                    )}
                >
                    <div className={classNames(className, 'lead__row--take')}>{children}</div>
                </Link>
            </Permission>
        );
    }

    return (
        <Link
            to={routeTo(
                ROUTES.CUSTOMER.PATH,
                {
                    id: lead?.contact?.id,
                },
                {
                    action: 'viewLead',
                    leadId: lead?.id,
                },
            )}
        >
            <div className={classNames(className, 'lead__row--show')}>{children}</div>
        </Link>
    );
};

export default LeadRowAction;
