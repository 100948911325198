import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { getStorage } from '@src/_metronic';

import useModal from '@app/hooks/useModal';

import { useAppSelector } from '@app/hooks';
import {
    DASHBOARD_SELECTED_DEALERSHIP_IDS,
    DASHBOARD_SELECTED_REFERENT_KEY,
} from '@app/pages/dashboard/partials/DashboardDealershipVendors';
import { User } from '@app/store/login/login.store';

import DashboardHeader from './DashboardHeader';
import DashboardProvider from './DashboardProvider';
import AgendaDetails from './modals/AgendaDetails';
import DashboardAchatCash from './partials/Stats/DashboardAchatCash';
import DashboardStatApv from './partials/Stats/DashboardStatApv';
import DashboardStatManagerAfrContact from './partials/Stats/DashboardStatManagerAfrContact';
import DashboardStats from './partials/Stats/DashboardStats';
import DashboardApv from './roles/apv/DashboardApv';
import DefaultWipTemplate from './roles/defaultWip/DefaultWipTemplate';
import DashboardDirector from './roles/director/DashboardDirector';
import DashboardManager from './roles/manager/DashboardManager';
import DashboardManagerAutosphereContact from './roles/managerAfrContact/DashboardManagerAutosphereContact';
import DashboardSeller from './roles/seller/DashboardSeller';

import './_dashboard.scss';

type DashboardParams = {
    dealership?: string;
    dealershipId?: string;
    dealer?: User;
    dealerId?: number;
    start: string;
    end: string;
};

export default function Dashboard() {
    const [showAgendaDetails, setShowAgendaDetails] = useModal(false, 'agenda_details');
    const selectedDealerships = getStorage(DASHBOARD_SELECTED_DEALERSHIP_IDS);
    const storedSelectedReferent = getStorage(DASHBOARD_SELECTED_REFERENT_KEY)
        ? JSON.parse(getStorage(DASHBOARD_SELECTED_REFERENT_KEY))
        : null;
    const { userData } = useAppSelector((state) => state.auth);

    const defaultState = {
        dealershipId: selectedDealerships || null,
        dealerId: storedSelectedReferent?.id || null,
        start: dayjs().startOf('month').format('DD/MM/YYYY'),
        end: dayjs().format('DD/MM/YYYY'),
    };

    const [params, setParams] = useState<DashboardParams>(defaultState);

    const [overriddenParams, setOverriddenParams] = useState<DashboardParams>(defaultState);

    let main = null;
    let role = null;
    let hideFilterCustomers = false;
    let hideFilters = false;
    switch (userData?.role?.slug) {
        case 'conseiller-commercial':
        case 'conseiller-achat-cash-concession':
        case 'conseiller-achat-cash-distance':
            main = <DashboardSeller displayProgressCircle />;
            role = 'conseiller-commercial';
            break;
        case 'manager':
        case 'manager-achats-cash':
        case 'planificateur':
            main = <DashboardManager />;
            role = 'manager';
            break;
        case 'responsable-apv':
            main = <DashboardManagerAutosphereContact />;
            role = 'responsable-apv';
            break;
        case 'conseiller-commercial-services':
            main = <DashboardApv />;
            role = 'ccs-apv';
            break;
        case 'manager-autosphere-contact':
            main = <DashboardManagerAutosphereContact />;
            role = 'manager-autosphere-contact';
            break;
        case 'agent-autosphere-contact':
            main = <DashboardSeller displayProgressCircle={false} />;
            role = 'agent-autosphere-contact';
            break;
        case 'agent-fid':
            main = <DefaultWipTemplate name="Fidélisation" image="dashboard-fidelisation-coming-soon.svg" />;
            role = 'agent-fid';
            hideFilters = true;
            break;
        default:
            main = <DashboardDirector />;
            role = 'directeur';
    }

    let stats = null;
    switch (userData?.role?.slug) {
        case 'manager-achats-cash':
        case 'planificateur':
        case 'conseiller-achat-cash-concession':
        case 'conseiller-achat-cash-distance':
            stats = <DashboardAchatCash className="mt-xl-0 mt-8" />;
            break;
        case 'responsable-apv':
            stats = <DashboardStatApv />;
            break;
        case 'conseiller-commercial-services':
            stats = <DashboardStatApv />;
            hideFilterCustomers = true;
            break;
        case 'agent-autosphere-contact':
            stats = <DashboardStatManagerAfrContact />;
            hideFilterCustomers = true;
            break;
        case 'manager-autosphere-contact':
            stats = <DashboardStatManagerAfrContact />;
            break;
        case 'conseiller-commercial':
            hideFilterCustomers = true;
            stats = <DashboardStats className="mt-xl-0 mt-8" />;
            break;
        case 'agent-fid':
            stats = null;
            break;
        default:
            stats = <DashboardStats className="mt-xl-0 mt-8" />;
    }

    useEffect(() => {
        const newParams = { ...params };

        const dealership = newParams?.dealership;
        delete newParams?.dealership;

        const dealer = newParams?.dealer;
        delete newParams?.dealer;

        if (dealership) {
            newParams.dealershipId = dealership;
        }

        if (dealer) {
            newParams.dealerId = dealer?.id;
        }

        if (
            newParams?.dealershipId !== overriddenParams?.dealershipId ||
            newParams?.dealerId !== overriddenParams?.dealerId ||
            newParams?.start !== overriddenParams?.start ||
            newParams?.end !== overriddenParams?.end
        ) {
            setOverriddenParams(newParams);
        }
    }, [role, hideFilterCustomers, params]);

    return (
        <DashboardProvider value={{ params, setParams, role, overriddenParams }}>
            <div className="d-flex flex-column h-100">
                <DashboardHeader hideFilterCustomers={hideFilterCustomers} hideFilters={hideFilters} />
                <div className={`dashboard dashboard-${role} h-100 d-flex flex-xl-row flex-column`}>
                    {main}
                    {stats}
                </div>
            </div>
            <AgendaDetails showModal={showAgendaDetails} setShowModal={setShowAgendaDetails} />
        </DashboardProvider>
    );
}
