import dayjs from 'dayjs';
import * as H from 'history';

import { routeTo } from '@app/helpers/RoutesHelper';

import { Performances } from '@app/crud/dashboard/achat-cash-types';
import { CrewActivity } from '@app/crud/dashboard/dashboard.crud';

import ROUTES from '@app/router/Routes';
import { Dealership } from '@app/store/login/login.store';

export const TODAY = 0;
export const TOMORROW = 1;

export const CURRENT_MONTH = 'currentMonth';
export const PREVIOUS_MONTH = 'previousMonth';

/**
 * Get shrinked job
 * @param {array} splittedJob
 */
export const getShrinkedJob = (splittedJob: string[]) =>
    splittedJob?.reduce?.((acc, curr) => acc + curr[0]?.toUpperCase?.(), '');

/**
 * Get small job
 * @param job
 * @returns {string}
 */
export const getSmallJob = (job: string) => {
    let result = '';
    const splittedJob = job?.split?.(' ');

    // If last part or job name as 2 chars (Ex: Responsable VO)
    if (splittedJob?.[splittedJob?.length - 1]?.length === 2) {
        const lastPart = splittedJob.splice(-1, 1);
        result = getShrinkedJob(splittedJob) + lastPart;
    } else {
        result = getShrinkedJob(splittedJob);
    }

    return result ? `(${result})` : '';
};

/**
 *
 * @returns {number|number}
 */
export const getTransformation = (won: number, lost = 0, total: number = null) => {
    if (!total) {
        total = (lost || 0) + (won || 0);
    }

    const percentage = (won * 100) / total || 0;

    return Math.round(percentage * 100) / 100;
};

/**
 * Get ranking color
 *
 * @param rank
 */
export const getRankingColor = (rank: number) => {
    const colors = ['yellow', 'dark-light', 'warning-gold'];

    return colors[rank] || 'initial';
};

/**
 * Sort ranking dealers
 *
 * @param a
 * @param b
 * @returns {number}
 */
export const sortRanking = (a: { leads: Performances }, b: { leads: Performances }) => {
    const aPercentage = getTransformation(a?.leads?.won, a?.leads?.lost, a?.leads?.total);
    const bPercentage = getTransformation(b?.leads?.won, b?.leads?.lost, b?.leads?.total);

    return bPercentage - aPercentage;
};

interface SortValuesParams {
    currentPeriod: string;
    dealership: Dealership;
    dealer: {
        id: number;
        first_name: string;
        last_name: string;
    };
    leadStatus: string;
    leadType: string;
    start: string;
    end: string;
}

/**
 * Get first day of current month
 *
 * @returns {dayjs.Dayjs}
 */
export const getFirstDayOfCurrentMonth = () => {
    const day = dayjs();
    return day.date(1);
};

/**
 * Get current month period
 *
 * @returns {string}
 */
export const getCurrentMonthPeriod = () => {
    const firstDayOfCurrentMonth = getFirstDayOfCurrentMonth();
    const lastDayOfCurrentMonth = firstDayOfCurrentMonth.date(firstDayOfCurrentMonth.daysInMonth());

    return [firstDayOfCurrentMonth.format('YYYY-MM-DD'), lastDayOfCurrentMonth.format('YYYY-MM-DD')].join(',');
};

/**
 * Get next month period
 */
export const getPrevMonthPeriod = () => {
    const firstDayOfCurrentMonth = getFirstDayOfCurrentMonth();

    const firstDayOfNextMonth = firstDayOfCurrentMonth.subtract(1, 'month');
    const lastDayOfNextMonth = firstDayOfNextMonth.date(firstDayOfNextMonth.daysInMonth());

    return [firstDayOfNextMonth.format('YYYY-MM-DD'), lastDayOfNextMonth.format('YYYY-MM-DD')].join(',');
};

/**
 * Get stats sort values
 *
 * @param params
 * @returns {{defaultFiltersValue: {}}}
 */
export const getStatsSortValues = (params: SortValuesParams) => {
    const receiptedPeriod =
        !params?.currentPeriod || params?.currentPeriod === CURRENT_MONTH
            ? getCurrentMonthPeriod()
            : getPrevMonthPeriod();

    return {
        defaultFiltersValue: {
            ...(params?.dealership && {
                dealership: {
                    value: params?.dealership?.title,
                },
            }),
            ...(params?.dealer?.id && {
                user: {
                    value: `${params?.dealer?.first_name} ${params?.dealer?.last_name}`,
                },
            }),
            ...(params?.leadStatus && {
                leadStatus: {
                    value: params.leadStatus,
                },
            }),
            ...(params?.leadType && {
                leadType: {
                    value: params.leadType,
                },
            }),
            receiptedPeriod: {
                value: receiptedPeriod,
            },
        },
    };
};

/**
 * Check if user is available for dealerships ranking
 *
 * @param role
 * @param params
 * @returns {boolean}
 */
export const isAvailableDealershipsRanking = (role: string, params: { dealership: Dealership }) =>
    ['directeur', 'responsable-marketing'].includes(role) && !params?.dealership;

/**
 * @deprecated you should use the autouser role shortName field instead of this method
 *
 * @param vendor
 * @returns {string}
 */
export const getShortRole = (vendor: CrewActivity) =>
    vendor?.role?.slug
        .replaceAll(/[-_]/g, ' ')
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase())
        .join('');

export const linkToLeadWithFilters = async (
    history: H.History,
    openNewTab = false,
    updateFilters: Function,
    filters = {},
    statusType = 'action-to-do',
    route = ROUTES.LEADS_ONLINE.PATH,
) => {
    await updateFilters({
        filters: {
            ...filters,
        },
    });
    (openNewTab ? window.open : history.push)(
        routeTo(route, {
            statusType,
        }),
        '_blank',
    );
};
