import ApiGateway, { ApiFacetsResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

const { REACT_APP_LEAD_URL, REACT_APP_SMART_REPAIR_URL, REACT_APP_APV_SC_URL, REACT_APP_APV_URL } = process.env;

export function getDashboardPendingCounters(params: object) {
    return ApiGateway.get<
        ApiFacetsResponse<PendingCounters, PendingCountersFacets>,
        CustomAxiosResponse<ApiFacetsResponse<PendingCounters, PendingCountersFacets>>
    >(`${REACT_APP_LEAD_URL}/dashboard/pending-counters`, {
        ...params,
    });
}

export function getDashboardAdvancement(params: object) {
    return ApiGateway.get<Advancement[], CustomAxiosResponse<Advancement[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/advancement`,
        {
            ...params,
        },
    );
}

export function getDashboardPriorityActions(params: object) {
    return ApiGateway.get<PriorityActions[], CustomAxiosResponse<PriorityActions[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/priority-actions`,
        {
            ...params,
        },
    );
}

export interface DashboardDealerRanking {
    firstname: string;
    lastname: string;
    dealership: string;
    leads: {
        lost: number;
        total: number;
        won: number;
    };
    typologieLead: string[];
}

export function getDashboardRanking(params: any) {
    return ApiGateway.get<DashboardDealerRanking[], CustomAxiosResponse<DashboardDealerRanking[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/ranking`,
        {
            ...params,
        },
    );
}

export function getDashboardPerformancesCounters(params: object) {
    return ApiGateway.get<PerformancesCounters[], CustomAxiosResponse<PerformancesCounters[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-counters`,
        {
            ...params,
        },
    );
}

export function getDashboardOnlineLeadsProcessing(params: object) {
    return ApiGateway.get<ReportData[], CustomAxiosResponse<ReportData[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/online-leads-processing`,
        {
            ...params,
        },
    );
}

export function getDashboardPerfomancesDetails(params: object) {
    return ApiGateway.get<Leads[], CustomAxiosResponse<Leads[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-details`,
        {
            ...params,
        },
    );
}

export function getDashboardCrewActivityForDirector(params: object) {
    return ApiGateway.get<Dealerships, CustomAxiosResponse<Dealerships>>(
        `${REACT_APP_LEAD_URL}/dashboard/crew-activity-for-director`,
        {
            ...params,
        },
    );
}

export interface Dealership {
    dealershipId: string;
    usualName: string;
    legalName: string;
    leads: number;
    apvs: number;
    actionsToDo: number;
    appointments: number;
    leadsWithActionsToDo: number;
    apvsWithActionsToDo: number;
    newApvs: number;
    newLeads: number;
    unreadNotes: number;
}

export interface Dealerships {
    [key: string]: Dealership;
}

interface LeadStatus {
    name: string;
    taken: number;
    takenStrong: number;
    inProgress: number;
    lost: number;
    lostStrong: number;
    won: number;
    wonStrong: number;
}

interface Leads {
    constructeur: LeadStatus;
    marketing_campaign: LeadStatus;
    achat_cash_abandon: LeadStatus;
    local_vn: LeadStatus;
    local_vo: LeadStatus;
    local_vd: LeadStatus;
    local_reprise: LeadStatus;
    local_achat_cash: LeadStatus;
    vo: LeadStatus;
    vn: LeadStatus;
    vd: LeadStatus;
    reprise: LeadStatus;
    achat_cash: LeadStatus;
    local_apv: LeadStatus;
    fid_vo: LeadStatus;
    fid_vn: LeadStatus;
}

interface ReportData {
    takenInLessThan30min: {
        success: number;
        total: number;
    };
    callTransferOffHook: {
        success: number;
        total: number;
    };
    closedInLessThan24hours: {
        success: number;
        total: number;
    };
    inProgressSinceMoreThan21days: number;
}

export interface PerformancesCounters {
    inProgress: number;
    lost: number;
    total: number;
    won: number;
}

interface Lead {
    id: number;
    owner: {
        id: number;
        firstname: string;
        lastname: string;
    };
    contactOrigin: any;
}

interface Contact {
    id: number;
    firstname: string;
    lastname: string;
}

interface LeadType {
    name: string;
    slug: string;
    groupName: string;
    groupSlug: string;
}

interface LeadStatus {
    name: string;
    subName: string;
    slug: string;
}

interface ScheduleAppointment {
    id: null | number;
    dateStart: null | string;
    isHomeTrial: null | boolean;
    isPhysical: null | boolean;
}

interface Action {
    id: number;
    scheduleAppointment: ScheduleAppointment;
}

export interface PriorityActions {
    lead: Lead;
    contact: Contact;
    leadType: LeadType;
    leadStatus: LeadStatus;
    action: Action;
}

export interface Advancement {
    todo: number;
    done: number;
    total: number;
}

export interface PendingCounters {
    lead: number | null;
    apv: number | null;
    note: number | null;
}

export interface PendingCountersFacetLeadTypes {
    count: number;
    slug: string;
    name: string;
}

export interface PendingCountersFacets {
    lead: PendingCountersFacetLeadTypes[];
}

export interface PartDetailPendingLead {
    count: number;
    slug: string;
    name: string;
}

export interface Role {
    name: string;
    type: string;
    slug: string;
    shortName?: string;
}

export interface CrewActivity {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    role: Role;
    isAPV: boolean;
    actionsToDo: number;
    leadsInProgress: number;
    appointment: number;
    processedAPVOverLast30Days: number;
    processedOverLast30Days: {
        count: number;
        status: string;
    }[];
}

export function getDashboardCrewActivityForManager(params: unknown) {
    return ApiGateway.get<CrewActivity[], CustomAxiosResponse<CrewActivity[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/crew-activity-for-manager`,
        {
            params,
        },
    );
}

export interface PerformancesApv {
    total: number;
    new: number;
    done: number;
    inProgress: number;
}

export function getDashboardCrewActivity(dealershipId: string) {
    return ApiGateway.get<CrewActivity[], CustomAxiosResponse<CrewActivity[]>>(
        `${REACT_APP_LEAD_URL}/dashboard/${dealershipId}/crew-activity`,
    );
}

export function getPrioritizedActions(userid: number) {
    return ApiGateway.get(`${REACT_APP_LEAD_URL}/action/${userid}/prioritized`);
}

export function getDashboardPerformancesApv(params: object) {
    return ApiGateway.get<PerformancesApv, CustomAxiosResponse<PerformancesApv>>(
        `${REACT_APP_LEAD_URL}/dashboard/performances-apv`,
        {
            params,
        },
    );
}

export function getDashboardAllOrdersSr(params: object) {
    return ApiGateway.get<number, CustomAxiosResponse<number>>(
        `${REACT_APP_SMART_REPAIR_URL}/api/indicator/withParams`,
        { params },
    );
}

export function getDashboardAllOrdersRasc(params: object) {
    return ApiGateway.get<number, CustomAxiosResponse<number>>(`${REACT_APP_APV_SC_URL}/api/indicator/withParams`, {
        params,
    });
}
export interface LocalLeads {
    created_local_lead_count: number;
    objective: number;
}

export function getObjectiveLocalLeads() {
    return ApiGateway.get<LocalLeads, CustomAxiosResponse<LocalLeads>>(
        `${REACT_APP_LEAD_URL}/dashboard/local-lead-creation-objective`,
    );
}

interface OrdersCounterParams {
    dealershipIds?: string[];
    currentPeriod?: 'previousMonth' | 'currentMonth';
}

export function getOrdersCounter(params: OrdersCounterParams) {
    return ApiGateway.get<number, CustomAxiosResponse<number>>(`${REACT_APP_APV_URL}/order/counter`, { params });
}
