import classNames from 'classnames';
import dayjs from 'dayjs';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { Divider } from 'primereact/divider';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import KTUtil from '@src/_metronic/_assets/js/util';
import { Locale } from '@src/_metronic/i18n/I18nProvider';

import { capitalize } from '@app/helpers/StringHelper';
import { displayPhoneNumber } from '@app/helpers/ToolsHelper';

import { usePermissions } from '@app/hooks';
import { hasConsents, mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import { getRPAByBrand, RPA_BRANDS } from '@app/pages/customers/Helpers/RPA';
import CustomerNotes from '@app/pages/customers/Partials/CustomerNotes';
import { updateCustomer } from '@app/store/customers/customers.thunk';
import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';
import Permission from '@app/partials/content/Permission';
import SidebarWrapper from '@app/partials/layout/SidebarWrapper';

import ProInfo from './ProInfo';
import RPAButton from './RPAButton';
import SidebarConsent from './SidebarConsent';

const CustomerInfo = ({ client, leads, reviews, activity }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [visibleConsent, setVisibleConsent] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const customerAsEmail = !!client?.email;
    const { userData } = useSelector((state) => state.auth);
    const hasDifferentialVCU =
        client?.differentialsVCU &&
        typeof client?.differentialsVCU === 'object' &&
        Object.keys(client?.differentialsVCU).length > 0;
    const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(undefined);
    const { hasPermission } = usePermissions();

    const [brandsRPA, setBrandsRPA] = useState([]);

    useEffect(() => {
        setIsVisiblePhoneNumber(displayPhoneNumber(client));

        if (client?.vehicleKnow && client?.vehicleKnow.length > 0) {
            setVehicles(
                client?.vehicleKnow.filter(
                    (elm, index) =>
                        index === client?.vehicleKnow.findIndex((item) => item.vehicleUuid === elm.vehicleUuid),
                ),
            );
        }
    }, [client]);

    useEffect(() => {
        if (RPA_BRANDS.length > 0 && Object.keys(userData?.dealerships || [])?.length > 0) {
            const RPAlist = [];
            Object.values(userData?.dealerships).forEach((dealership) => {
                if (dealership.isActiveRpa) {
                    const RPA = getRPAByBrand(dealership.brand);
                    if (!RPAlist.includes(RPA)) {
                        RPAlist.push(RPA);
                    }
                }
            });
            setBrandsRPA(RPAlist);
        }
    }, [userData]);

    const updateCustomerCallback = (customer) => {
        const customerMapped = mapVcuCustomer(customer);
        customerMapped.user = {
            id: userData.id,
            firstname: userData.first_name,
            lastname: userData.last_name,
        };
        const id = client?.id;
        dispatch(updateCustomer(id, customerMapped, history));
    };

    const createdAt = () => (
        <div className="d-flex justify-content-between border-top">
            <div className="d-flex font-size-sm px-9 py-3">
                <span className="mr-2 text-muted">
                    <FormattedMessage id="CUSTOMER.VIEW.INFO.CREATE_AT" />
                </span>
                <span className="mr-2">{dayjs(client?.created).format('DD/MM/YYYY [à] HH [h] mm')}</span>
            </div>
        </div>
    );

    const clientDisplayedConsent = useMemo(
        () => client?.consents?.find?.((consent) => consent?.refDealership === userData?.default_dealership),
        [client, userData],
    );

    const phone = parsePhoneNumberFromString(client?.phone || '', Locale.toUpperCase());
    const mobile = parsePhoneNumberFromString(client?.mobile || '', Locale.toUpperCase());

    return (
        <Card className="card--shadowed multi">
            <Card.Header className={KTUtil.isMobileDevice() && 'py-4'}>
                <div className="d-flex align-items-lg-center align-items-start justify-content-between">
                    <div className="d-flex flex-column align-items-left">
                        <div className="d-flex flex-row align-items-center">
                            <i className="la la-user la-2x text-light" />
                            <span>
                                <FormattedMessage
                                    id={`CUSTOMER.VIEW.INFO.${client?.isClient ? 'CLIENT' : 'PROSPECT'}.TITLE`}
                                />
                            </span>
                        </div>
                    </div>
                    <div className="d-flex">
                        {brandsRPA?.length > 0 &&
                            brandsRPA.map((RPA) => (
                                <RPAButton
                                    RPA={RPA}
                                    key={RPA.tool}
                                    customer={client}
                                    isCustomerInRpa={client?.rpaIdentifiants?.find?.(
                                        (rpaId) => rpaId?.app === RPA?.tool.toLowerCase() && !rpaId?.isDisabled,
                                    )}
                                />
                            ))}
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="position-relative p-0" style={hasDifferentialVCU ? { overflow: 'unset' } : {}}>
                <div className="p-9">
                    {client.isPro ? (
                        <ProInfo
                            hasDifferentialVCU={hasDifferentialVCU}
                            updateCustomerCallback={updateCustomerCallback}
                            client={client}
                            dispatch={dispatch}
                            clientDisplayedConsent={clientDisplayedConsent}
                            customerAsEmail={customerAsEmail}
                            phone={phone}
                            mobile={mobile}
                            isVisiblePhoneNumber={isVisiblePhoneNumber}
                        />
                    ) : (
                        <>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="font-weight-bold font-size-h4">{`${
                                    client?.civility ? `${client?.civility}.` : ''
                                } ${capitalize(client?.firstname) || ''} ${
                                    client?.lastname?.toUpperCase() || ''
                                }`}</div>
                                <div className="btn-status btn-status--customer font-weight-bold">
                                    <FormattedMessage id="CUSTOMER.VIEW.INFO.STATUS.CUSTOMER" />
                                </div>
                            </div>
                            <Permission permissions={['LEAD_UPDATE_CONTACT']}>
                                <div className="d-flex align-items-center">
                                    <Button
                                        className="p-0 text-primary"
                                        onClick={() =>
                                            dispatch(
                                                actions.modalChange('create', {
                                                    customer: client,
                                                    update: true,
                                                    callbackCustomer: updateCustomerCallback,
                                                }),
                                            )
                                        }
                                        variant="outline-link"
                                    >
                                        <u>
                                            <FormattedMessage id="CUSTOMERS.MODAL.UPDATE.TITLE" />
                                        </u>
                                    </Button>
                                    {hasDifferentialVCU && (
                                        <Permission permissions={['LEAD_UPDATE_VCU_CONTACT']}>
                                            <Label
                                                className="label-warning text-dark d-flex align-items-center px-4 ml-5 d-block font-weight-bold flag-new-data"
                                                onClick={() =>
                                                    dispatch(
                                                        actions.modalChange('rollback_update', {
                                                            differentialsVCU: true,
                                                            customerFieldChange: client?.differentialsVCU,
                                                            callback: updateCustomerCallback,
                                                        }),
                                                    )
                                                }
                                            >
                                                <FormattedMessage id="CUSTOMER.VIEW.INFO.NEW_DATA" />
                                            </Label>
                                        </Permission>
                                    )}
                                </div>
                            </Permission>
                            {(client?.address || client?.zipcode || client?.city) && (
                                <div className="mt-5">
                                    {client?.address ? (
                                        <>
                                            {client?.address} <br />
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {client?.zipcode || ''} {client?.city || ''}
                                </div>
                            )}
                            {(phone || mobile) && isVisiblePhoneNumber === undefined && <div>-</div>}
                            {!isVisiblePhoneNumber && (
                                <Button
                                    variant="primary"
                                    className="w-lg-auto w-100"
                                    onClick={() => dispatch(actions.modalChange('contactAudi'))}
                                >
                                    <FormattedMessage id="CUSTOMER.SHOW_MAIN_PHONE_NUMBER" />
                                </Button>
                            )}
                            {(phone || mobile) && isVisiblePhoneNumber && (
                                <div className="d-lg-flex align-items-center mt-5 gap-10">
                                    {mobile && (
                                        <div>
                                            <span>
                                                <FormattedMessage id="CUSTOMERS.MODAL.UPDATE.MOBILE" />
                                            </span>
                                            {` `}
                                            <a
                                                href={`tel:${mobile.number}`}
                                                className="d-inline-block font-weight-bold font-size-h6"
                                            >
                                                {mobile.formatNational()}
                                            </a>
                                        </div>
                                    )}
                                    {phone && (
                                        <div>
                                            <span>
                                                <FormattedMessage id="CUSTOMERS.MODAL.UPDATE.PHONE" />
                                            </span>
                                            {` `}
                                            <a
                                                href={`tel:${phone.number}`}
                                                className="d-inline-block font-weight-bold font-size-h6"
                                            >
                                                {phone.formatNational()}
                                            </a>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                    {customerAsEmail && (
                        <Permission permissions={['LEAD_SEND_MAIL_CONTACT']}>
                            <div className="mt-5 d-lg-flex text-lg-left text-center align-items-center">
                                <Button
                                    variant="primary"
                                    className="w-lg-auto w-100"
                                    onClick={() => dispatch(actions.modalChange('mail'))}
                                >
                                    <FormattedMessage id="CUSTOMER.SEND_EMAIL" />
                                </Button>
                                <div className="ml-lg-5 mt-lg-0 mt-2">
                                    <FormattedMessage id="TRANSLATOR.TO" />
                                    {` `}
                                    {client?.email}
                                </div>
                            </div>
                        </Permission>
                    )}
                    {clientDisplayedConsent && (
                        <div className="mt-5">
                            <FormattedMessage id="CUSTOMER.CONSENT" />
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="quick-panel-tooltip" className="tooltip-left tooltip-dark tooltip-lg">
                                        Dans le cas du traitement d’un Lead, vous avez le droit de contacter le client{' '}
                                        <strong>par tous les canaux disponibles</strong>.
                                        <br />
                                        Ces consentements ne sont à prendre en compte{' '}
                                        <strong>qu’en cas de démarchage ou de prospection</strong>.
                                    </Tooltip>
                                }
                            >
                                <i className="cursor-pointer las la-info-circle" />
                            </OverlayTrigger>
                            <FormattedHTMLMessage
                                id="CUSTOMER.CONSENT.DEALER"
                                values={{
                                    dealer_name: clientDisplayedConsent?.name || '',
                                }}
                            />
                            <div className="mt-2 d-flex flex-wrap text-nowrap justify-content-between align-items-center">
                                {['Email', 'Phone', 'Sms', 'Post'].map((gdrp) => (
                                    <div
                                        key={gdrp}
                                        className={classNames('d-flex flex-wrap text-nowrap align-items-center', {
                                            'text-success': clientDisplayedConsent?.[`gdpr${gdrp}`],
                                        })}
                                    >
                                        <i
                                            className={classNames(
                                                {
                                                    'las la-lg la-check-circle mr-2':
                                                        clientDisplayedConsent[`gdpr${gdrp}`],
                                                    'las la-lg la-times-circle mr-2':
                                                        !clientDisplayedConsent[`gdpr${gdrp}`],
                                                },
                                                {
                                                    'text-success': clientDisplayedConsent[`gdpr${gdrp}`],
                                                },
                                            )}
                                        />
                                        <FormattedHTMLMessage id={`TRANSLATOR.CONSENT_${gdrp?.toUpperCase?.()}`} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {!hasPermission('VIEW_ACTIONS_BLOCK') && (
                        <Permission permissions={['LEAD_VIEW_VEHICLES_KNOW_CONTACT']}>
                            <Divider type="dashed" className="my-6" />

                            <>
                                <div className="mr-2 p-0 col-4">
                                    <FormattedHTMLMessage
                                        id="CUSTOMER.VIEW.INFO.VEHICLE"
                                        values={{ number: vehicles?.length }}
                                    />
                                </div>
                                {vehicles.length > 0
                                    ? vehicles?.map((vehicle) => (
                                          <div
                                              key={vehicle.vehicleUuid}
                                              className="d-flex justify-content-between align-items-start mb-2"
                                          >
                                              <div
                                                  role="presentation"
                                                  className="text-primary cursor-pointer w-100"
                                                  onClick={() =>
                                                      dispatch(
                                                          actions.modalChange('vehicle_know', {
                                                              customer: client,
                                                              vehicles,
                                                          }),
                                                      )
                                                  }
                                              >
                                                  <u>
                                                      <span>
                                                          {' '}
                                                          {vehicle?.brand} {vehicle?.model}
                                                          {` `}({vehicle?.registrationNumber})
                                                      </span>
                                                  </u>
                                              </div>
                                              {vehicle?.isSaleProduct === true && (
                                                  <span
                                                      className={`label label-inline label-light-orange px-6 text-nowrap ${
                                                          KTUtil.isMobileDevice() ? 'mb-2' : 'mr-2'
                                                      }`}
                                                      style={{ minHeight: '0' }}
                                                  >
                                                      <FormattedMessage id="ELIGIBLE.FOR.LIFETIME.CT" />
                                                  </span>
                                              )}
                                          </div>
                                      ))
                                    : '-'}
                            </>
                        </Permission>
                    )}
                    <Divider className="my-6 p-divider-dashed" />
                    <div className="d-flex">
                        <div className="mr-2 p-0 col-4">
                            <FormattedMessage id="CUSTOMER.VIEW.INFO.DEALERSHIP" />
                        </div>
                        <strong>
                            {client?.dealershipKnow && client?.dealershipKnow[0]
                                ? client?.dealershipKnow[0]?.refDealershipName
                                : '-'}
                        </strong>
                    </div>
                    <div className="d-flex">
                        <div className="mr-2 p-0 col-4">
                            <FormattedMessage id="CUSTOMER.VIEW.INFO.REFERENT" />
                        </div>
                        <strong>
                            {leads[0]?.owner?.firstname || activity?.data?.length > 0
                                ? `${leads[0]?.owner?.firstname || activity?.data[0].eventUserFirstname || ''} ${
                                      leads[0]?.owner?.lastname || activity?.data[0].eventUserLastname || ''
                                  }`
                                : '-'}
                        </strong>
                    </div>
                    <Divider className="mt-6 mb-3 p-divider-dashed" />
                    <Row className="d-flex justify-content-around">
                        <Permission permissions={['LEAD_VIEW_REVIEW_CONTACT']}>
                            <OverlayTrigger
                                placement="top"
                                trigger={!reviews?.length || !customerAsEmail ? ['hover', 'focus'] : null}
                                overlay={
                                    <Tooltip id="tooltip-fidcar-disabled">
                                        <FormattedMessage id="CUSTOMER.VIEW.INFO.NO_FEEDBACK" />
                                    </Tooltip>
                                }
                            >
                                <div>
                                    <Button
                                        variant={!reviews?.length || !customerAsEmail ? 'secondary' : 'success'}
                                        onClick={() => dispatch(actions.modalChange('feedback'))}
                                        className="mt-3"
                                        disabled={!reviews?.length || !customerAsEmail}
                                    >
                                        <i className="la la-star" />
                                        <FormattedMessage id="CUSTOMER_REVIEW" />
                                    </Button>
                                </div>
                            </OverlayTrigger>
                        </Permission>
                        <Permission permissions={['LEAD_UPDATE_CONSENT_CONTACT']}>
                            <Button
                                variant="primary"
                                className="mt-3"
                                disabled={!hasConsents(client)}
                                onClick={() => setVisibleConsent(true)}
                            >
                                <i className="la la-bullhorn" />
                                <FormattedMessage id="CONSENT" />
                            </Button>
                        </Permission>

                        <Permission permissions={['LEAD_SHOW_CUSTOMER_NOTE']}>
                            <CustomerNotes />
                        </Permission>
                    </Row>
                </div>
                {createdAt()}
            </Card.Body>
            <SidebarWrapper
                visible={visibleConsent}
                setVisible={setVisibleConsent}
                header={{ title: 'CUSTOMER.SIDEBAR_CONSENT.TITLE', icon: 'la-user' }}
            >
                <SidebarConsent updateCustomerCallback={updateCustomerCallback} />
            </SidebarWrapper>
        </Card>
    );
};

export default CustomerInfo;
