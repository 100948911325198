import { useState } from 'react';

import { Action, ActionClient, ActionPayload, ActionResponse } from '@app/crud/action/base';
import PermissionError from '@app/crud/action/exception/permission-error';
import RuntimeError from '@app/crud/action/exception/runtime-error';
import { getCustomer } from '@app/crud/customers/customer.crud';

import { useAppDispatch, usePermissions } from '@app/hooks';
import { actions } from '@app/store/customers/customers.store';
import { Action as LeadAction, actions as leadActions } from '@app/store/lead/lead.store';

import toast from '@app/partials/content/Toast';

interface Options {
    permission?: string;
    notifySucess?: string;
    notifyFail?: string;
}

const useActionClient = <P extends ActionPayload, R extends ActionResponse>(
    client: ActionClient<P, R>,
    { permission, notifySucess, notifyFail }: Options,
): [Action<P, R>, boolean] => {
    const [loading, setLoading] = useState(false);
    const { hasPermission } = usePermissions();
    const dispatch = useAppDispatch();

    const caller = (payload: P) => {
        setLoading(true);

        if (permission && !hasPermission(permission)) {
            throw new PermissionError();
        }

        let leadAction: LeadAction | null = null;
        if (client.relatedTo === 'lead') {
            if (client.action && client.reference?.(payload)) {
                leadAction = {
                    action: client.action,
                    id: client.reference?.(payload),
                };

                dispatch(leadActions.startAction(leadAction));
            }
        }

        return client
            .execute(payload)
            .then(async (response) => {
                if (response.relatedToCustomer) {
                    const customer = await getCustomer(response.relatedToCustomer);

                    if (!customer?.result?.contact) {
                        throw new RuntimeError('no_contact');
                    }

                    if (customer?.result?.leads) {
                        dispatch(actions.leadUpdated(customer.result.contact, customer.result.leads, ''));
                    }
                }

                setLoading(false);

                if (leadAction) {
                    dispatch(leadActions.stopAction(leadAction));
                }

                if (notifySucess) {
                    toast({
                        variant: 'success',
                        message: notifySucess,
                    });
                }

                return response;
            })
            .catch((e) => {
                setLoading(false);
                if (leadAction) {
                    dispatch(leadActions.stopAction(leadAction));
                }
                if (notifyFail) {
                    toast({
                        variant: 'danger',
                        message: notifyFail,
                    });
                }
                throw e;
            });
    };

    return [caller, loading];
};

export default useActionClient;
