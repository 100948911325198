import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayloadRelatedToLead, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class TakeLeadClient implements ActionClient<TakeLeadPayload, TakeLeadResponse> {
    public async execute(payload: TakeLeadPayload): Promise<TakeLeadResponse> {
        return ApiGateway.post<ApiResponse<TakeLeadResponse>, CustomAxiosResponse<ApiResponse<TakeLeadResponse>>>(
            `${REACT_APP_LEAD_URL}/action/take-lead`,
            payload,
        ).then((response: ApiResponse<TakeLeadResponse>) => {
            if (response.result?.error) {
                throw new Error(response.result?.error);
            }

            return {
                relatedToLead: response.result.id,
                relatedToCustomer: response.result.contact.id,
                ...response.result,
            };
        });
    }

    public readonly action: string = 'take-lead';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: TakeLeadPayload): number {
        return payload.lead;
    }
}

export interface TakeLeadPayload extends ActionPayloadRelatedToLead {}

export interface TakeLeadResponse extends ActionResponse {
    id: number;
    owner: Person;
    contact: {
        id: number;
    };
}

interface Person {
    id: number;
    firstname: string;
    lastname: string;
}
