import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { mapSecurywebToFieldForm } from '@app/crud/mapping/securyweb.map';
import { exportContractPdf, getProducts } from '@app/crud/securyweb/securyweb.crud';
import { StatusContractSlug } from '@app/crud/securyweb/securyweb.types';
import type { Contract, ContractType } from '@app/crud/securyweb/securyweb.types';

import variantTypes from '@app/constants';
import { useFetch } from '@app/hooks';
import { getStatusClassName, getStatusLabelId } from '@app/pages/securyweb/Helpers/SecurywebHelper';
import { actions } from '@app/store/modal/modal.store';

import Label from '@app/partials/content/Label';
import Loader from '@app/partials/content/Loader';
import toast from '@app/partials/content/Toast';

interface ContractRowProps {
    contract: Contract;
}

const ContractRow = ({ contract }: ContractRowProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const [isDownloading, setIsDownloading] = useState(false);
    const statusClassName = getStatusClassName(contract.statut);
    const statusLabelId = getStatusLabelId(contract.statut);
    const isValidContract = contract?.statut === StatusContractSlug.VALID;

    const { fetch: fetchProducts, data: products } = useFetch({
        fetchAction: getProducts,
        resultInterceptor: (response) => response.result,
    });

    const handleDownloadButtonClick = (contractId: string, contractType: ContractType) => {
        setIsDownloading(true);
        exportContractPdf(contractId, contractType)
            .catch(() => {
                toast({
                    variant: variantTypes.DANGER,
                    message: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
                });
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    useEffect(() => {
        if (products && products.length > 0) {
            const mappedData = mapSecurywebToFieldForm({ ...contract });

            dispatch(
                actions.modalChange('contract_securycar_steps', {
                    allProducts: products,
                    formValues: {
                        product: { ...contract.produit },
                        ...mappedData,
                    },
                }),
            );
        }
    }, [products, dispatch]);

    return (
        <div className={classNames('lead-row')} role="presentation">
            {isDownloading && <Loader style={{ width: '5rem', height: '5rem' }} overlay />}
            <div className="lead-row__type lead-row__type--local" />
            <div className="lead-row__wrapper">
                <div className="lead-row__picture-wrapper">
                    <Image className="lead-row__picture" src="/media/pages/customers/bonhomme-securycar.svg" />
                </div>
                <div className="lead-row__content py-4">
                    <div className="d-lg-flex mb-lg-0 mb-2 d-block align-items-center">
                        <span className="font-size-h3 font-weight-bold text-nowrap">
                            <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.TITLE" />
                        </span>
                    </div>
                    <span className={classNames('flex-wrap font-size-sm')}>
                        <Label
                            className={classNames('font-weight-bold label-lg', {
                                [`label-light-${statusClassName}`]: statusClassName,
                            })}
                        >
                            <FormattedMessage id={statusLabelId} />
                        </Label>
                    </span>
                    <div>{contract?.concession?.denomination ?? ''}</div>
                    <div>
                        <FormattedMessage id="CUSTOMERS.LEAD_ROW.LAST_UPDATE" />{' '}
                        {dayjs(contract?.dateModification || contract?.dateCreation).format('DD/MM/YYYY [à] HH [h] mm')}
                    </div>
                    {(contract?.vendeur?.nom || contract?.vendeur?.prenom) && (
                        <div>
                            <FormattedMessage id="CUSTOMERS.LEAD_ROW.REFERENT" />
                            {' : '}
                            <span>
                                {contract?.vendeur?.nom || ''} {contract?.vendeur?.prenom || ''}
                            </span>
                        </div>
                    )}
                </div>
                <div className="lead-row__actions py-lg-4 pt-0 pb-4">
                    <Button variant="danger" onClick={() => fetchProducts(contract?.concession.code)}>
                        {isValidContract ? (
                            <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.ACTION.VIEW" />
                        ) : (
                            <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.ACTION.RESUME" />
                        )}
                    </Button>
                    {isValidContract && (
                        <Button
                            variant="outline-primary"
                            onClick={() => handleDownloadButtonClick(contract?.id, contract?.typeContrat)}
                            className="w-100 mt-4"
                        >
                            <FormattedMessage id="CUSTOMERS.CONTRACT_ROW.SECURYCAR.ACTION.DOWNLOAD" />
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ContractRow;
