import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button, Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@app/hooks';
import useAction from '@app/pages/customers/Hooks/useAction';
import useLead from '@app/pages/customers/Hooks/useLead';
import DealershipVendors from '@app/pages/customers/Partials/DealershipVendors';
import { User } from '@app/store/login/login.store';

import ModalDefault from '@app/partials/content/modals/Modal.default';
import toast from '@app/partials/content/Toast';

import ChangeOwnerClient from '@app/crud/action/change-owner';
import useActionClient from '../Hooks/useActionClient';

type AssignLeadProps = {
    showModal: boolean;
    setShowModal: Dispatch<SetStateAction<boolean>>;
};

type Vendor = Omit<User, 'permissions'> & {
    active: boolean;
    alert_fifteen_min: boolean;
    can_be_impersonated: boolean;
    lead_alert: boolean;
    outlook_planning: boolean;
};

const AssignLead = ({ showModal, setShowModal }: AssignLeadProps) => {
    const Intl = useIntl();
    const [referent, setReferent] = useState(null);
    const [dealership, setDealership] = useState(null);
    const [loading, setLoading] = useState(null);
    const { params } = useAppSelector((state) => state.modal);
    const lead = useLead(params?.leadId, params?.lead);

    const [caller, changeOwnerLoading] = useActionClient(new ChangeOwnerClient(), {
        notifySucess: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ASSIGN_LEAD.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.ASSIGN_LEAD.ERROR' }),
    });

    const changeOwner = () => {
        caller({
            lead: lead?.id,
            owner: referent?.id,
            dealership: dealership?.refDealershipId,
        })
            .then(() => {
                setShowModal(false);
            })
            .catch((reason) => {
                console.error(reason);
            });
    };

    const getListSlugReferent = () => {
        const referentSlug = ['manager', 'conseiller-commercial'];
        if (
            lead?.leadType?.slug &&
            ['achat_cash', 'achat_cash_abandon', 'local_reprise', 'local_achat_cash', 'reprise'].includes(
                lead.leadType.slug,
            )
        ) {
            referentSlug.push('conseiller-achat-cash-concession', 'conseiller-achat-cash-distance');
        } else if (lead?.leadType?.slug && ['local_apv'].includes(lead.leadType.slug)) {
            referentSlug.push('conseiller-commercial-services', 'responsable-apv');
        }

        return referentSlug;
    };

    return (
        <ModalDefault
            show={showModal}
            loading={changeOwnerLoading || loading}
            hideModal={() => setShowModal(false)}
            icon={<i className="las la-2x text-primary la-user" />}
            title={
                params?.update
                    ? Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.UPDATE.TITLE' })
                    : Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.TITLE' })
            }
            body={
                <>
                    {/* @ts-ignore */}
                    <DealershipVendors
                        referent={referent}
                        setReferent={setReferent}
                        setDealership={setDealership}
                        setLoading={setLoading}
                        referentsInterceptor={(vendors: Vendor[]) => {
                            getListSlugReferent();
                            return vendors
                                ?.filter((vendor) => {
                                    const vendorHasAvailableRole = getListSlugReferent().includes(vendor?.role?.slug);
                                    const vendorHasTypologyLead = vendor?.tags?.typologie_lead?.includes?.(
                                        lead?.leadType?.slug,
                                    );
                                    return vendorHasTypologyLead && vendorHasAvailableRole;
                                })
                                .sort((a, b) => {
                                    if (a?.role?.slug === b?.role?.slug) {
                                        const lastNameA = a?.last_name?.toLowerCase() || '';
                                        const lastNameB = b?.last_name?.toLowerCase() || '';

                                        if (lastNameA > lastNameB) return 1;
                                        if (lastNameA < lastNameB) return -1;
                                        return 0;
                                    }

                                    return a?.role?.slug < b?.role?.slug ? 1 : -1;
                                });
                        }}
                        dealership={lead?.dealershipId || lead?.dealership?.id}
                        page={undefined}
                        className={undefined}
                        setReferents={undefined}
                    />
                    <div className="d-flex justify-content-center align-items-center position-relative">
                        <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                            <div className="mt-8 mb-5">
                                {params?.update ? (
                                    <Image height={200} src="/media/pages/customers/changement-de-referent.svg" />
                                ) : (
                                    <Image height={200} src="/media/pages/customers/referent-affecter-un-lead.svg" />
                                )}
                            </div>
                            <div className="d-flex w-100 align-items-center justify-content-between">
                                {params?.update && (
                                    <div className="text-center text-success mb-5" style={{ zIndex: 1 }}>
                                        {Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.ACTUAL' })}
                                        <br />
                                        <span className="font-weight-bold">
                                            {lead?.owner?.firstname} {lead?.owner?.lastname}
                                        </span>
                                    </div>
                                )}
                                <div className="text-center text-success mb-5" style={{ zIndex: 1 }}>
                                    {params?.update
                                        ? Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.NEW' })
                                        : Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT' })}
                                    <br />
                                    <span className="font-weight-bold">
                                        {referent?.id ? `${referent?.first_name} ${referent?.last_name}` : '-'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            footer={
                <div className="float-right">
                    <Button variant="outline-secondary" onClick={() => setShowModal(false)} className="mr-5">
                        {Intl.formatMessage({ id: 'TRANSLATOR.CANCEL' })}
                    </Button>
                    <Button variant="primary" disabled={!referent?.id} onClick={changeOwner}>
                        {Intl.formatMessage({ id: 'TRANSLATOR.VALIDATE' })}
                    </Button>
                </div>
            }
        />
    );
};

export default AssignLead;
