import classNames from 'classnames';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';

import { routeTo } from '@app/helpers/RoutesHelper';

import { getDashboardPendingCounters } from '@app/crud/dashboard/dashboard.crud';

import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import { linkToLeadWithFilters } from '@app/pages/dashboard/helpers/DashboardHelper';
import ROUTES from '@app/router/Routes';

interface DashboardPendingCountersProps {
    className?: string;
    displayApv?: boolean;
}

// can be disable for debug links with filters
const openNewTab = false;

const DashboardPendingCounters = ({ className, displayApv = true }: DashboardPendingCountersProps) => {
    const history = useHistory();
    const { overriddenParams } = useContext(DashboardContext);
    const [linkOverriddenParams, setLinkOverriddenParams] = useState<{
        dealershipIds: string[];
    }>();
    const {
        data: { result: pendingCounters },
        loading,
        fetch,
    } = useFetch({
        fetchAction: getDashboardPendingCounters,
        resultInterceptor: (response) => response,
    });

    const { updateFilters } = useFilters({
        name: `leads_online_new`,
    });

    const { updateFilters: updateApvFilters } = useFilters({
        name: `leads_apv_new`,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams }).then();
        }

        setLinkOverriddenParams({
            dealershipIds: overriddenParams?.dealershipId?.split(',') ?? [],
        });
    }, [overriddenParams?.dealershipId]);

    if (loading) {
        return <DashboardPendingCounters.Skeleton />;
    }

    return (
        <div className="container">
            <div className="row d-flex justify-content-between align-items-stretch gap-3">
                {pendingCounters?.lead !== null && (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <a
                            className={classNames(
                                'dashboard-lead col-sm justify-content-center align-items-start',
                                { isReversed: pendingCounters?.lead === 0 },
                                className,
                            )}
                            onClick={() =>
                                linkToLeadWithFilters(
                                    history,
                                    openNewTab,
                                    updateFilters,
                                    { ...linkOverriddenParams },
                                    'new',
                                    ROUTES.LEADS_ONLINE.PATH,
                                )
                            }
                        >
                            <div className="font-weight-bold d-flex align-items-center">
                                <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                    {pendingCounters?.lead}
                                </div>
                                <FormattedHTMLMessage id="DASHBOARD.LEAD.TITLE" />
                            </div>
                        </a>
                    </>
                )}
                {pendingCounters?.apv !== null && displayApv === true && (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                        <a
                            className={classNames(
                                'dashboard-lead col-sm justify-content-center align-items-start',
                                { isReversed: pendingCounters?.apv === 0 },
                                className,
                            )}
                            onClick={() =>
                                linkToLeadWithFilters(
                                    history,
                                    openNewTab,
                                    updateApvFilters,
                                    { ...linkOverriddenParams },
                                    'new',
                                    ROUTES.APV_LEADS.PATH,
                                )
                            }
                        >
                            <div className="font-weight-bold d-flex align-items-center justify-content-center">
                                <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                    {pendingCounters?.apv}
                                </div>
                                <FormattedHTMLMessage id="DASHBOARD.APV.TITLE" />
                            </div>
                        </a>
                    </>
                )}

                {pendingCounters?.note !== null && (
                    <Link
                        className={classNames(
                            'dashboard-lead col-sm justify-content-center align-items-start',
                            { isReversed: pendingCounters?.note === 0 },
                            className,
                        )}
                        to={{
                            pathname: routeTo(ROUTES.NOTES.PATH, {
                                status: 'toTake',
                            }),
                        }}
                    >
                        <div className="font-weight-bold d-flex align-items-center justify-content-center">
                            <div className="dashboard-lead-pending-counters__total font-weight-bold">
                                {pendingCounters?.note}
                            </div>
                            <FormattedHTMLMessage id="DASHBOARD.NOTE.TITLE" />
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

DashboardPendingCounters.Skeleton = () => <Skeleton borderRadius="10px" className="w-100 p-15" />;

export default DashboardPendingCounters;
