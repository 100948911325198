import classNames from 'classnames';
import dayjs from 'dayjs';
import { Skeleton } from 'primereact/skeleton';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useFetch from '@app/hooks/useFetch';
import useFilters from '@app/hooks/useFilters';

import { getDashboardPerformancesCounters } from '@app/crud/dashboard/dashboard.crud';

import ROUTES from '@app/router/Routes';

import { DashboardContext } from '../../DashboardProvider';
import { linkToLeadWithFilters } from '../../helpers/DashboardHelper';

// can be disable for debug links with filters
const openNewTab = true;

const DashboardGeneralPerf = ({ className }) => {
    const history = useHistory();
    const { overriddenParams } = useContext(DashboardContext);
    const { data, loading, fetch } = useFetch({
        fetchAction: getDashboardPerformancesCounters,
    });
    const [linkOverriddenParams, setLinkOverriddenParams] = useState();

    const { updateFilters } = useFilters({
        name: `leads_online_all`,
    });

    const { updateFilters: updateFiltersClosed } = useFilters({
        name: `leads_online_close`,
    });

    useEffect(() => {
        if (overriddenParams) {
            fetch({ params: overriddenParams }).then();
        }
        const dateStart = dayjs(overriddenParams?.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const dateEnd = overriddenParams?.end
            ? dayjs(overriddenParams?.end, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : dateStart;

        setLinkOverriddenParams({
            dealershipIds: overriddenParams?.dealershipId?.split(',') ?? [],
            receiptedPeriod: {
                value: [dateStart, dateEnd].join(','),
            },
            userIds: overriddenParams?.dealerId ? [overriddenParams.dealerId] : [],
        });
    }, [overriddenParams]);

    if (loading) {
        return <DashboardGeneralPerf.Skeleton />;
    }

    return (
        <div className={classNames('dashboard-general-perf d-flex flex-column', className)}>
            <div className="font-size-lg font-weight-bold d-flex flex-wrap align-items-center justify-content-between">
                <div className="d-flex flex-wrap align-items-center">
                    <i className="dashboard-general-perf__icon las la-2x la-chart-line mr-2" />
                    <span className="mr-4">
                        <FormattedMessage id="DASHBOARD.GENERAL_PERF.TITLE" />
                    </span>
                </div>
            </div>
            <div className="dashboard-general-perf__groups align-items-center flex-grow-1">
                <div className="dashboard-general-perf__leads flex-grow-1">
                    <div className="d-flex justify-content-between">
                        <i className="las la-2x la-chart-bar" />
                        <span className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_TOTAL" />
                        </span>
                        <span className="dashboard-general-perf__lead ml-3 text-dark-50 font-weight-semibold text-right flex-grow-1">
                            {data?.total || 0}
                        </span>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a
                        className="d-flex justify-content-between text-initial"
                        onClick={() =>
                            linkToLeadWithFilters(
                                history,
                                openNewTab,
                                updateFilters,
                                { ...linkOverriddenParams },
                                'all',
                                ROUTES.LEADS_ONLINE.PATH,
                            )
                        }
                    >
                        <i className="las la-2x la-bullseye" />
                        <span className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_IN_PROGRESS" />
                        </span>
                        <span className="dashboard-general-perf__lead ml-3 text-dark-50 font-weight-semibold text-right flex-grow-1">
                            {data?.inProgress || 0}
                        </span>
                    </a>
                </div>
                <div className="dashboard-general-perf__leads flex-grow-1">
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a
                        className="d-flex justify-content-between text-initial"
                        onClick={() =>
                            linkToLeadWithFilters(
                                history,
                                openNewTab,
                                updateFiltersClosed,
                                { ...linkOverriddenParams, leadStatus: ['closed_won'] },
                                'close',
                                ROUTES.LEADS_ONLINE.PATH,
                            )
                        }
                    >
                        <i className="text-success kt-opacity-5 las la-2x la-thumbs-up" />
                        <span className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_WON" />
                        </span>
                        <span className="dashboard-general-perf__lead ml-3 text-success font-weight-semibold text-right flex-grow-1">
                            {data?.won || 0}
                        </span>
                    </a>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <a
                        className="d-flex justify-content-between text-initial"
                        onClick={() =>
                            linkToLeadWithFilters(
                                history,
                                openNewTab,
                                updateFiltersClosed,
                                { ...linkOverriddenParams, leadStatus: ['closed_lost'] },
                                'close',
                                ROUTES.LEADS_ONLINE.PATH,
                            )
                        }
                    >
                        <i className="text-danger kt-opacity-5 las la-2x la-thumbs-down" />
                        <span className="dashboard-general-perf__state ml-2 line-height-sm text-nowrap">
                            <FormattedHTMLMessage id="DASHBOARD.GENERAL_PERF.LEAD_LOST" />
                        </span>
                        <span className="dashboard-general-perf__lead ml-3 text-danger font-weight-semibold text-right flex-grow-1">
                            {data?.lost || 0}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

DashboardGeneralPerf.Skeleton = () => (
    <Skeleton borderRadius="10px" className="d-flex flex-column flex-lg-grow-1 mt-lg-2 mt-6 p-15" />
);

export default DashboardGeneralPerf;
