import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';

import { DashboardContext } from '@app/pages/dashboard/DashboardProvider';
import ROUTES from '@app/router/Routes';

import { linkToLeadWithFilters } from '../../helpers/DashboardHelper';

// can be disable for debug links with filters
const openNewTab = true;

const DashboardPerfState = ({ detail, leadType }) => {
    const history = useHistory();
    const { overriddenParams } = useContext(DashboardContext);
    const [linkOverriddenParams, setLinkOverriddenParams] = useState();

    useEffect(() => {
        const dateStart = dayjs(overriddenParams?.start, 'DD/MM/YYYY').format('YYYY-MM-DD');
        const dateEnd = overriddenParams?.end
            ? dayjs(overriddenParams?.end, 'DD/MM/YYYY').format('YYYY-MM-DD')
            : dateStart;

        setLinkOverriddenParams({
            dealershipIds: overriddenParams?.dealershipId?.split(',') ?? [],
            receiptedPeriod: {
                value: [dateStart, dateEnd].join(','),
            },
            userIds: overriddenParams?.dealerId ? [overriddenParams.dealerId] : [],
        });
    }, [overriddenParams]);

    const { updateFilters } = useFilters({
        name: `leads_online_all`,
    });

    const { updateFilters: updateFiltersClosed } = useFilters({
        name: `leads_online_close`,
    });

    const { updateFilters: updateApvFilters } = useFilters({
        name: `leads_apv_action_to_do`,
    });

    const { updateFilters: updateApvFiltersClosed } = useFilters({
        name: `leads_apv_close`,
    });
    const isAPV = leadType === 'local_apv';
    return (
        <div className="d-flex flex-nowrap font-size-sm font-weight-semibold">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-primary text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateApvFilters,
                              { ...linkOverriddenParams },
                              'action_to_do',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateFilters,
                              { ...linkOverriddenParams, leadType: [leadType] },
                              'all',
                              ROUTES.LEADS_ONLINE.PATH,
                          )
                }
            >
                <u>
                    {detail?.inProgress ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.IN_PROGRESS" />
                </u>
            </a>
            <span className="mx-1">,</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-danger text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateApvFiltersClosed,
                              { ...linkOverriddenParams },
                              'close',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateFiltersClosed,
                              {
                                  ...linkOverriddenParams,
                                  leadType: [leadType],
                                  leadStatus: ['closed_lost'],
                              },
                              'close',
                              ROUTES.LEADS_ONLINE.PATH,
                          )
                }
            >
                <u>
                    {detail?.lost ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.LOST" />
                </u>
            </a>
            <span className="mx-1">,</span>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a
                className="text-success text-nowrap"
                onClick={() =>
                    isAPV
                        ? linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateApvFiltersClosed,
                              { ...linkOverriddenParams },
                              'close',
                              ROUTES.APV_LEADS.PATH,
                          )
                        : linkToLeadWithFilters(
                              history,
                              openNewTab,
                              updateFiltersClosed,
                              { ...linkOverriddenParams, leadType: [leadType], leadStatus: ['closed_won'] },
                              'close',
                              ROUTES.LEADS_ONLINE.PATH,
                          )
                }
            >
                <u>
                    {detail?.won ?? 0} <FormattedMessage id="DASHBOARD.ONLINE_PERF.WON" />
                </u>
            </a>
        </div>
    );
};

export default DashboardPerfState;
