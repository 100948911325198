import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getMetaFile } from '@app/crud/meta.crud';

const CacheBounding = ({ children }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const updateLastestVersion = useCallback(() => {
        getMetaFile().then((response) => {
            if (response?.data?.version) {
                const current_version = localStorage.getItem('APP_VERSION');

                if (current_version !== response.data.version) {
                    dispatch({ type: 'RESET_STATE_VERSION_CHANGED' });
                    localStorage.setItem('APP_VERSION', response.data.version);
                    window.location.href = window.location.href;
                }
            }
        });
    }, []);

    useEffect(() => {
        const unlisten = history.listen((location, action) => {
            updateLastestVersion();
        });

        return () => {
            unlisten();
        };
    }, [updateLastestVersion]);

    useEffect(() => {
        updateLastestVersion();
    }, [updateLastestVersion]);

    return children;
};

export default CacheBounding;
