import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayloadRelatedToLead, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class ChangeOwnerClient implements ActionClient<ChangeOwnerPayload, ChangeOwnerResponse> {
    public async execute(payload: ChangeOwnerPayload): Promise<ChangeOwnerResponse> {
        return ApiGateway.post<ApiResponse<ChangeOwnerResponse>, CustomAxiosResponse<ApiResponse<ChangeOwnerResponse>>>(
            `${REACT_APP_LEAD_URL}/action/change-owner`,
            payload,
        ).then((response: ApiResponse<ChangeOwnerResponse>) => {
            if (response.result?.error) {
                throw new Error(response.result?.error);
            }

            return {
                relatedToLead: response.result.id,
                relatedToCustomer: response.result.contact.id,
                ...response.result,
            };
        });
    }

    public readonly action: string = 'change-owner';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: ChangeOwnerPayload): number {
        return payload.lead;
    }
}

export interface ChangeOwnerPayload extends ActionPayloadRelatedToLead {
    owner: number;
    dealership: string;
}

export interface ChangeOwnerResponse extends ActionResponse {
    id: number;
    owner: Person;
    contact: {
        id: number;
    };
}

interface Person {
    id: number;
    firstname: string;
    lastname: string;
}
