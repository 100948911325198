import classNames from 'classnames';
import React from 'react';
import '@app/pages/customers/customer.scss';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import usePermissions from '@app/hooks/usePermissions';

import { routeTo } from '@app/helpers/RoutesHelper';

import { mapVcuCustomer } from '@app/pages/customers/Helpers/Customer';
import ROUTES from '@app/router/Routes';
import { createCustomer } from '@app/store/customers/customers.thunk';

const CustomerRow = ({ customer, infoBody, nameBody, phoneBody, updatedBody }) => {
    const { hasPermission } = usePermissions();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleClick = () => {
        if (customer?.id) {
            if (hasPermission('LEAD_VIEW_CONTACT')) {
                history.push(
                    routeTo(ROUTES.CUSTOMER.PATH, {
                        id: customer.id,
                    }),
                );
            }
        } else if (hasPermission('LEAD_CREATE_CONTACT')) {
            dispatch(
                createCustomer({
                    data: {
                        ...mapVcuCustomer({
                            ...customer,
                        }),
                    },
                    history,
                }),
            );
        }
    };

    return (
        <div className="customer__row" onClick={handleClick} role="presentation">
            <div>
                {infoBody(customer)}
                <span
                    className={classNames({
                        'text-primary': customer?.isClient,
                        'text-danger': !customer?.isClient,
                    })}
                >
                    {' '}
                    :{' '}
                </span>
                {nameBody(customer)}
            </div>
            {phoneBody(customer) && (
                <a
                    className="customer__phone d-block"
                    href={`tel:${phoneBody(customer, false)}`}
                    onClick={(e) => e.stopPropagation()}
                >
                    <FormattedMessage id="CUSTOMERS.CALL" />
                    {` `}
                    <span className="customer__phone--bold">{phoneBody(customer)}</span>
                </a>
            )}
            {customer?.city && (
                <div className="d-flex align-items-center text-secondary">
                    <i className="las la-map-marker mr-2" />
                    {customer?.city}
                </div>
            )}
            {customer?.dealershipKnow?.[0]?.refDealershipName && (
                <div className="d-flex align-items-center font-weight-normal">
                    <i className="las la-building mr-2" />
                    {customer?.dealershipKnow?.[0]?.refDealershipName}
                </div>
            )}
            {customer?.owner?.firstname && customer?.owner?.lastname && (
                <div className="d-flex align-items-center text-secondary">
                    <i className="las la-user mr-2" />
                    {customer.owner?.firstname} {customer.owner?.lastname}
                </div>
            )}
            {customer?.updated && (
                <div className="text-secondary">
                    <FormattedMessage id="CUSTOMERS.UPDATE" />
                    {` `}
                    {updatedBody(customer)}
                </div>
            )}
            <div className="customer__arrow">
                <i className="las la-angle-right" />
            </div>
        </div>
    );
};

export default CustomerRow;
