import dayjs from 'dayjs';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import StatusLead from '@app/pages/customers/Partials/StatusLead';

import RowInfo from '@app/partials/content/RowInfo';

type LeadLocalHomeChargeInfoProps = {
    event: LeadHomeChargeView;
};

/**
 * Interface that retrieves common lead fields
 * It needs to be extended with the specific fields for each lead type
 */
interface BaseLeadView {
    id: number;
    owner: {
        id: number;
        firstname: string;
        lastname: string;
    };
    dealershipSource: {
        id: string;
        usualName: string;
    };
    leadType: {
        name: string;
        slug: string;
    };
    leadSource: {
        name: string;
        slug: string;
    };
    lastStatus: {
        name: string;
        slug: string;
        subName: null;
    };
    lastUser: {
        id: number;
        firstname: string;
        lastname: string;
        fullname: string;
    };
    receipted: string;
}

interface LeadHomeChargeView extends BaseLeadView {
    homeCharge: {
        id: number;
        url: string;
        externalStatus: string;
        deliveryDate: string;
        vehicleModel: string;
        vehicleBrand: string;
        vehicleType: string;
        usage: string;
        chargerType: string;
        product: string;
        batteryCapacity: number;
        contactFirstname: string;
        contactLastname: string;
        contactGender: string;
        isPro: boolean;
        contactEmail: string;
        contactMobile: string;
        contactAddress: string;
        contactZipcode: string;
        contactCity: string;
        contactSiren: null;
        contactCompanyName: null;
    };
}

const LeadLocalHomeChargeInfo = ({ event }: LeadLocalHomeChargeInfoProps) => {
    const Intl = useIntl();

    const leadStatus = (
        <StatusLead
            leadStatusUpdated={null}
            className="d-block d-xxl-flex align-items-center flex-wrap mt-2"
            statusSlug={event?.lastStatus?.slug}
            statusName={event?.lastStatus?.name}
            statusSubName={event?.lastStatus?.subName}
        />
    );

    return (
        <Row>
            <Col>
                <RowInfo label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.ID' })} value={event?.id} />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DATE_CREATED' })}
                    value={event?.receipted ? dayjs(event?.receipted).format('DD/MM/YYYY [à] HH [h] mm') : '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BRAND' })}
                    value={event?.homeCharge?.vehicleBrand}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.MODEL' })}
                    value={event?.homeCharge?.vehicleModel}
                />
            </Col>
            <Col>
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.VEHICLE_USAGE' })}
                    value={event?.homeCharge?.usage}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.VEHICLE_MODEL_TYPE' })}
                    value={event?.homeCharge?.vehicleType}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.CHARGER_TYPE' })}
                    value={event?.homeCharge?.chargerType}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.BATTERY_CAPACITY' })}
                    value={`${event?.homeCharge?.batteryCapacity} kW`}
                />
            </Col>
            <Col>
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.PRODUCT' })}
                    value={event?.homeCharge?.product || '-'}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.DEALERSHIP' })}
                    value={event?.dealershipSource?.usualName}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.FOLLOW_BY' })}
                    value={`${event?.owner?.firstname} ${event?.owner?.lastname}`}
                />
                <RowInfo
                    label={Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.INFO.STATUS' })}
                    value={leadStatus}
                />
            </Col>
        </Row>
    );
};

export default LeadLocalHomeChargeInfo;
