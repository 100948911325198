import { IsValidJson } from '@app/helpers/JsonHelper';

/**
 * Map customer
 *
 * @param customer
 */
export const mapCustomer = (customer) => ({
    id: customer?.id,
    civility: customer?.civility,
    firstname: customer?.firstname,
    lastname: customer?.lastname,
    email: customer?.email,
    phone: customer?.phone,
    mobile: customer?.mobile,
    address: customer?.address,
    additionalAddress: customer?.additionalAddress,
    country: customer?.country,
    zipcode: customer?.zipcode,
    city: customer?.city,
    birthday: customer?.birthday,
    vehicleKnow: IsValidJson(customer?.vehiclesKnow) ? JSON.parse(customer?.vehiclesKnow) : customer?.vehiclesKnow,
    dealershipKnow: IsValidJson(customer?.dealershipsKnow)
        ? JSON.parse(customer?.dealershipsKnow)
        : customer?.dealershipsKnow,
    isClient: customer?.isClient,
    isAnonymized: customer?.isAnonymized,
    vcuId: customer?.vcuID,
    autosphereId: customer?.autosphereID,
    deliveryId: customer?.deliveryID,
    dateAnonymized: customer?.dateAnonymized,
    created: customer?.created,
    updated: customer?.updated,
    consents: customer?.consents,
    owner: customer?.lead?.owner,
    leadSourceName: customer?.lead?.source?.name,
    leadSourceSlug: customer?.lead?.source?.slug,
    leadStatusName: customer?.lead?.status?.name,
    leadStatusSlug: customer?.lead?.status?.slug,
    leadStatusSubName: customer?.lead?.status?.sub_name,
    leadStatusUpdate: customer?.lead?.status?.update,
    leadTypeName: customer?.lead?.type?.name,
    leadTypeSlug: customer?.lead?.type?.slug,
    siren: customer?.siren,
    siret: customer?.siret,
    ape: customer?.ape,
    companyName: customer?.company?.name,
    companyLegalStatus: customer?.company?.legalStatus,
    companyPhone: customer?.company?.phone,
    proPhone: customer?.pro_phone,
    isPro: customer?.isPro,
    ...(customer?.gdprAutosphere && customer?.gdprAutosphere?.gdprEmail !== null
        ? { gdprAutosphere: customer?.gdprAutosphere }
        : {}),
    ...(customer?.gdprSecurycar && customer?.gdprSecurycar?.gdprEmail !== null
        ? { gdprSecurycar: customer?.gdprSecurycar }
        : {}),
    ...(customer?.gdprDealerships ? { gdprDealerships: customer?.gdprDealerships } : {}),
});

/** *
 * Map customers
 *
 * @param customers
 */
export const mapCustomers = (customers) =>
    customers.map((customer) => {
        if (customer?.contact_id || customer?.contact_firstname || customer?.contact_lastname) {
            return legacyMapCustomer(customer);
        }

        return mapCustomer(customer);
    });

const legacyMapCustomer = (customer) => ({
    id: customer?.contact_id,
    civility: customer?.contact_civility,
    firstname: customer?.contact_firstname,
    lastname: customer?.contact_lastname,
    email: customer?.contact_email,
    phone: customer?.contact_phone,
    mobile: customer?.contact_mobile,
    address: customer?.contact_address,
    additionalAddress: customer?.contact_additional_address,
    country: customer?.contact_country,
    zipcode: customer?.contact_zipcode,
    city: customer?.contact_city,
    birthday: customer?.birthday,
    vehicleKnow: IsValidJson(customer?.contact_vehicle_know)
        ? JSON.parse(customer?.contact_vehicle_know)
        : customer?.contact_vehicle_know,
    dealershipKnow: IsValidJson(customer?.contact_dealership_know)
        ? JSON.parse(customer?.contact_dealership_know)
        : customer?.contact_dealership_know,
    isClient: customer?.contact_is_client,
    isAnonymized: customer?.contact_is_anonymized,
    vcuId: customer?.contact_vcu_id,
    autosphereId: customer?.contact_autosphere_id,
    deliveryId: customer?.contact_delivery_id,
    dateAnonymized: customer?.contact_date_anonymized,
    created: customer?.contact_created,
    updated: customer?.contact_updated,
    consents: customer?.contact_consents,
    owner: customer?.lead?.owner,
    leadSourceName: customer?.lead_source_name,
    leadSourceSlug: customer?.lead_source_slug,
    leadStatusName: customer?.lead_status_name,
    leadStatusSlug: customer?.lead_status_slug,
    leadStatusSubName: customer?.lead_status_sub_name,
    leadStatusUpdate: customer?.lead_last_status_update,
    leadTypeName: customer?.lead_type_name,
    leadTypeSlug: customer?.lead_type_slug,
    siren: customer?.siren,
    siret: customer?.siret,
    ape: customer?.ape,
    companyName: customer?.contact_company_name,
    companyLegalStatus: customer?.contact_company_legal_status,
    companyPhone: customer?.contact_company_phone,
    proPhone: customer?.contact_pro_phone,
    isPro: customer?.contact_is_pro,
    ...(customer?.gdprAutosphere && customer?.gdprAutosphere?.gdprEmail !== null
        ? { gdprAutosphere: customer?.gdprAutosphere }
        : {}),
    ...(customer?.gdprSecurycar && customer?.gdprSecurycar?.gdprEmail !== null
        ? { gdprSecurycar: customer?.gdprSecurycar }
        : {}),
    ...(customer?.gdprDealerships ? { gdprDealerships: customer?.gdprDealerships } : {}),
});
