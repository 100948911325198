import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { getStorage, removeStorage, setStorage } from '@src/_metronic';

import useFetch from '@app/hooks/useFetch';
import useVendors from '@app/hooks/useVendors';

import { getUserPerimeter } from '@app/crud/autouser/user.crud';

import { User } from '@app/store/login/login.store';

import { TreeSelect } from '../../../partials/content/Tree';
import {
    DealershipTreeSelectNode,
    getCheckedDealerships,
    getPerimeterDealership,
    setPerimeterSelectedDealership,
    updateDatas,
} from '../../autouser/users/Helpers/Dealership';
import { getShortRole } from '../helpers/DashboardHelper';

const DASHBOARD_SELECTED_DEALERSHIP_KEY = `dashboard_selected_dealership`;
export const DASHBOARD_SELECTED_DEALERSHIP_IDS = `dashboard_selected_dealership_ids`;
export const DASHBOARD_SELECTED_REFERENT_KEY = `dashboard_selected_referent`;

interface DashboardDealershipVendorsProps {
    referent: User;
    setReferent: (referent: User) => void;
    dealership: string;
    setDealership: (dealership: string) => void;
    className?: string;
    dealershipClassName?: string;
    vendorsClassName?: string;
    dealershipEmptyLabel?: string;
    vendorsEmptyLabel?: string;
    labels?: boolean;
    showClear?: boolean;
    showReferent?: boolean;
    referentsInterceptor: (vendors: User[]) => User[];
    page: string;
}

const tagMaxLength = 1;

const DashboardDealershipVendors = ({
    referent,
    setReferent,
    setDealership,
    className,
    dealership,
    labels,
    dealershipClassName,
    vendorsClassName,
    showClear,
    dealershipEmptyLabel,
    vendorsEmptyLabel,
    showReferent,
    page,
    referentsInterceptor,
}: DashboardDealershipVendorsProps) => {
    const treeRef = useRef();
    const Intl = useIntl();
    const { fetchVendors, vendors } = useVendors();
    const { register, setValue } = useFormContext();
    const [selectedDealership, setSelectedDealership] = useState(dealership);
    const [selectedReferent, setSelectedReferent] = useState(referent);
    const [displayedPerimeter, setDisplayedPerimeter] = useState([]);
    const [selectedDealer, setSelectedDealer] = useState([]);
    const { data: perimeter } = useFetch({
        // @ts-ignore
        fetchAction: getUserPerimeter,
        resultInterceptor: (response: DealershipTreeSelectNode[]) => {
            const selectedDealershipIds = getStorage(DASHBOARD_SELECTED_DEALERSHIP_KEY);
            const dealership_ids = selectedDealershipIds && selectedDealershipIds.split(',');
            return updateDatas(response, '', null, dealership_ids, page);
        },
        axios: false,
        autoFetch: true,
    });

    const handleCounterSelectedDealerships = (selectedListDealer: DealershipTreeSelectNode[]) => {
        const moreIndicatorElement = document.getElementById('moreIndicatorElement') as HTMLElement | null;
        const tagList = document.getElementsByClassName('tag-list')[0] as HTMLElement;
        const tags = Array.from(tagList.children) as HTMLElement[];

        tags.forEach((tag, index) => {
            if (index < tagMaxLength) {
                tag.style.display = 'inline-block';
            } else {
                // Hide the rest of the tags
                tag.style.display = 'none';
            }
        });
        // Adjust the "+ number of dealers selected" indicator as per the current selection
        if (selectedListDealer.length > tagMaxLength) {
            // If more than two dealerships are selected, show the indicator
            if (moreIndicatorElement) {
                moreIndicatorElement.textContent = `+${selectedListDealer.length - tagMaxLength}`;
                moreIndicatorElement.style.display = 'inline-block';
            } else {
                // Create the "+ more" indicator if it does not exist
                const newIndicatorElement = document.createElement('span');
                newIndicatorElement.id = 'moreIndicatorElement';
                newIndicatorElement.textContent = `+${selectedListDealer.length - tagMaxLength}`;
                newIndicatorElement.style.position = 'absolute';
                newIndicatorElement.style.top = '10px';
                newIndicatorElement.style.right = '15px';
                tagList.parentNode?.insertBefore(newIndicatorElement, tagList.nextSibling);
            }
        } else {
            // If two or fewer dealerships are selected, remove the indicator
            moreIndicatorElement?.parentNode?.removeChild(moreIndicatorElement);
        }
    };

    const handleSelectedDealerships = (
        currentNode: DealershipTreeSelectNode,
        selectedNodes: DealershipTreeSelectNode[] = [],
    ) => {
        if (!Array.isArray(selectedNodes)) return;
        const checkedNodes = selectedNodes.map((selectedNode) => getPerimeterDealership(perimeter, selectedNode?.id));
        setSelectedDealer(selectedNodes);
        // selected dealership ids for api
        const selectedDealerships = getCheckedDealerships(checkedNodes);
        // selected dealership to string
        const listDealershipCodeJoined = selectedDealerships.map((dealershipItem) => dealershipItem?.id).join(',');

        setDealership(listDealershipCodeJoined);
        setSelectedDealership(listDealershipCodeJoined);
        setStorage(DASHBOARD_SELECTED_DEALERSHIP_KEY, JSON.stringify(checkedNodes));
        setStorage(DASHBOARD_SELECTED_DEALERSHIP_IDS, listDealershipCodeJoined);
    };

    const handleSelectedReferent = (e: DropdownChangeEvent) => {
        const referentItem = e.value || '';
        setReferent(referentItem);
        setSelectedReferent(referentItem);
        setStorage(DASHBOARD_SELECTED_REFERENT_KEY, JSON.stringify(referentItem));
    };

    useEffect(() => {
        register('perimeter');
    }, [register]);

    useEffect(() => {
        if (selectedDealership) {
            fetchVendors(selectedDealership);
        }
    }, [selectedDealership]);

    useEffect(() => {
        if (Object.keys(perimeter)?.length > 0) {
            const storedSelectedDealership = getStorage(DASHBOARD_SELECTED_DEALERSHIP_KEY)
                ? JSON.parse(getStorage(DASHBOARD_SELECTED_DEALERSHIP_KEY))
                : null;
            const storedSelectedReferent = getStorage(DASHBOARD_SELECTED_REFERENT_KEY)
                ? JSON.parse(getStorage(DASHBOARD_SELECTED_REFERENT_KEY))
                : null;

            const perimeterIsSame = storedSelectedDealership?.every((dealershipItem: DealershipTreeSelectNode) => {
                if (getPerimeterDealership(perimeter, dealershipItem?.id)) {
                    return true;
                }
                removeStorage(DASHBOARD_SELECTED_DEALERSHIP_KEY);
                removeStorage(DASHBOARD_SELECTED_REFERENT_KEY);
                return false;
            });

            if (perimeterIsSame) {
                setSelectedReferent(storedSelectedReferent);
                setPerimeterSelectedDealership(perimeter, storedSelectedDealership);
                handleSelectedDealerships(null, storedSelectedDealership);
            }

            setDisplayedPerimeter(updateDatas(perimeter));
            setValue('perimeter', perimeter);
        }
    }, [perimeter]);

    useEffect(() => {
        handleCounterSelectedDealerships(selectedDealer);
    }, [selectedDealer]);

    return (
        <>
            <Form.Group className={className}>
                {labels && (
                    <Form.Label>{Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT' })}</Form.Label>
                )}
                <TreeSelect
                    // @ts-ignore TODO typings
                    data={displayedPerimeter}
                    texts={{
                        placeholder: dealershipEmptyLabel,
                        inlineSearchPlaceholder: Intl.formatMessage({ id: 'CUSTOMER.MODAL.SEARCH.DEALERSHIP' }),
                    }}
                    inlineSearchInput
                    ref={treeRef}
                    keepOpenOnSelect
                    className={dealershipClassName}
                    onChange={(
                        currentNode: DealershipTreeSelectNode,
                        selectedNodes: DealershipTreeSelectNode[],
                    ): void => {
                        handleSelectedDealerships(currentNode, selectedNodes);
                        handleCounterSelectedDealerships(selectedDealer);
                    }}
                />
            </Form.Group>
            {showReferent && (
                <Form.Group className={className}>
                    {labels && (
                        <Form.Label>{Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.REFERENT.SELECT' })}</Form.Label>
                    )}
                    <Dropdown
                        value={selectedReferent?.id ? selectedReferent : null}
                        className={vendorsClassName}
                        options={referentsInterceptor(vendors)}
                        onChange={handleSelectedReferent}
                        itemTemplate={(vendor) =>
                            `${vendor?.first_name} ${vendor?.last_name} (${getShortRole(vendor)})`
                        }
                        valueTemplate={(vendor) =>
                            vendor
                                ? `${vendor?.first_name} ${vendor?.last_name} (${getShortRole(vendor)})`
                                : vendorsEmptyLabel
                        }
                        optionLabel="id"
                        showClear={showClear}
                        emptyMessage={
                            selectedDealership
                                ? Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT_EMPTY' })
                                : Intl.formatMessage({ id: 'CUSTOMER.MODAL.ASSIGN.DEALERSHIP.SELECT_UNKNOWN' })
                        }
                    />
                </Form.Group>
            )}
        </>
    );
};

DashboardDealershipVendors.defaultProps = {
    labels: true,
    className: '',
    dealershipClassName: '',
    vendorsClassName: '',
    showClear: false,
    dealershipEmptyLabel: 'Aucune concession sélectionnée',
    vendorsEmptyLabel: 'Aucun vendeur sélectionné',
    showReferent: true,
};

export default DashboardDealershipVendors;
